import React from "react";
import Header from "./Header";
import Nav from "./Nav";
import CountUp from 'react-countup';

const Hero = () => {
  return (
    <>
  
    <div className="bg-container  md:py-[150px] 2xl:py-[200px] w-[100%] text-white">
    <div className="header md:absolute relative top-[20px] left-0 right-0 bottom-0 max-w-[1400px] w-full mx-auto"><Header/>

    </div>
      <div className="max-w-[1200px] w-full mx-auto md:pt-[0] pt-[80px] p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="image-container text-left">
          <h2 className="text-white md:text-[56px] text-[30px] mont italic m-0" data-aos="fade-up"  data-aos-duration="1000">Cricket Collectible <span className="yellow mont">NFT Marketplace</span></h2>
          <p className="text-white md:text-[18px] md:leading-[30px] text-[16px] mont "data-aos="fade-up"  data-aos-duration="1500">
            CARO NFT is pioneering the PLAY2EARN revolution in NFT CRICKET
            COLLECTIBLE network of <b className="yellow">1 Billion </b>active cricket lovers, fantasy
            players & Crypto & NFT enthusiast.
          </p>

          <div className="flex flex-wrap md:gap-[80px] gap-[50px] md:mt-[40px]">
            <div className="border-right-cs">
              <h3 className="m-0 mont md:text-[30px] md:leading-[36px] font-bold">  <CountUp start={0} end={242} duration={10} separator="" />k+</h3>
              <h4 className="m-0 mont md:text-[20px] md:leading-[24px] font-normal mt-1">NFTs</h4>
            </div>
            <div className="border-right-cs">
              <h3 className="m-0 mont md:text-[30px] md:leading-[36px] font-bold"><CountUp start={0} end={265} duration={10} separator="" />K+</h3>
              <h4 className="m-0 mont md:text-[20px] md:leading-[24px] font-normal mt-1">Players</h4>
            </div>
            <div >
              <h3 className="m-0 mont md:text-[30px] md:leading-[36px] font-bold"> <CountUp start={0} end={4.3} duration={5} separator="" />.5M+</h3>
              <h4 className="m-0 mont md:text-[20px] md:leading-[24px] font-normal mt-1">Contests</h4>
            </div>
          </div>
          <button className="download-button md:mt-[50px] mt-[20px]" >Explore Market</button>
        </div>
        <div className="image-container flex items-center justify-center  md:my-[0px] my-[50px]" data-aos="fade-up"
     data-aos-duration="1000">
          <img 
            src="/img/nft-cards.png"
            alt="Right Image"
            className=" md-w-[574px] w-full h-auto object-cover"
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default Hero;
