import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";
import { HashLink } from "react-router-hash-link";

function Footer() {
  const navigate = useNavigate();
  const goToFAQ = () => {
    navigate("/");
    // const faqSection = document.getElementById('faq-section');
    // if (faqSection) {
    //     faqSection.scrollIntoView({ behavior: 'smooth' });
    // }
    setTimeout(() => {
      const faqSection = document.getElementById("faq-section");
      if (faqSection) {
        faqSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <div className="bg-[#0E133D] flex items-center justify-center w-[100%] text-white">
      <div className="max-w-[1200px] w-full mx-auto my-[20px] mb-[0px]">
        <div className=" w-full relative overflow-hidden text-white">
          <div className="flex  md:flex-row flex-col items-center justify-between md:gap-12 gap-0">
            <div className="flex md:flex-row flex-col gap-2">
              <div className=" flex gap-2 items-center justify-center md:justify-start ">
                <img
                  src="/footer-logo.svg"
                  className="md:w-[96px] h-[101.33px] "
                  alt=""
                />
                <h2 className="text-center text-bold mont md:text-[40px] md:leading-[40px] ">
                  Caro NFT
                </h2>
              </div>
            </div>

            <div className="text-center md:text-center italic md:text-[24px] md:leading-[36px] text-bold mont font-[700]">
              Explore our NFT Marketplace
            </div>

            <div className="flex flex-row gap-4 items-start justify-end">
              <div className="flex w-full m-0 items-start h-full py-7 md:p-0 justify-end">
                <div className="flex flex-row items-center text-center justify-end">
                  <span className="text-center font-[500] leading-6 text-base ">
                    <a
                      className="no-underline text-white"
                      href="mailto:support@caronft.com"
                    >
                      <button className="download-button w-[318px!important] md:w-[318px!important] flex items-center justify-center">
                        {" "}
                        <img
                          src="/message.svg"
                          alt=""
                          className=" h-8 w-8 "
                        />{" "}
                        support@caronft.com{" "}
                      </button>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse	 flex-col md:flex-row md:gap-16 gap-5 justify-between md:items-start z-[3] md:py-[40px] py-[0] md:my-[20px] border-solid border-y-2 border-[#FFFFFF1A;] md:w-[100%] w-[96%] mx-auto">
            <div className="col-6  md:w-[60%]">
              <div className="col">
                <p className="md:m-0 md:text-[20px] md:leading-[34px] font-[400] text-[16px] leading:[22px] mont md:text-start text-center">
                  Experience the ultimate cricket collectible gaming <br />
                  platform with NFTs. Play and
                  <br />
                  win amazing rewards.
                </p>
              </div>

              <div className="flex ms-2 items-center justify-center md:justify-start">
                <a href="https://www.facebook.com/metaricaro/" target="_blank">
                  {" "}
                  <img
                    src="/facebook.svg"
                    alt=""
                    className="w-15 h-15 ml-[-12px]"
                  />{" "}
                </a>
                <a href="https://x.com/metaricaro" target="_blank">
                  {" "}
                  <img
                    src="/twitter.svg"
                    alt=""
                    className="w-15 h-15 object-contain"
                  />{" "}
                </a>
                <a  href="https://www.instagram.com/caro_nft?igsh=MXAwa2tkYWg0a2RmYw=="
                  target="_blank">
                  {" "}
                  <img
                    src="/insta.svg"
                    alt=""
                    className="w-15 h-15 object-contain"
                  />{" "}
                </a>
                <a href="https://youtube.com/@carogames01?si=h0_IpHwjMX7pP2Mu" target="_blank">
                  {" "}
                  <img
                    src="/player.svg"
                    alt=""
                    className="w-15 h-15 object-contain "
                  />{" "}
                </a>

              </div>
            </div>

            <div className="flex flex-col gap-7 items-center md:items-start">
              <Link
                to="/about-us"
                className="text-start md:text-[20px] md:leading-[34px] font-[400] text-[16px] leading:[22px] mont no-underline text-white"
              >
                About Us
              </Link>
              <Link
                to="/how-to-play"
                className="text-start md:text-[20px] md:leading-[34px] font-[400] text-[16px] leading:[22px] montl no-underline text-white"
              >
                How to play
              </Link>

              <HashLink
                to="/#faq-section"
                className="text-start md:text-[20px] md:leading-[34px] font-[400] text-[16px] leading:[22px] mont no-underline text-white"
              >
                {" "}
                FAQ{" "}
              </HashLink>
            </div>

            <div className="flex flex-col gap-7 items-center md:items-start md:pt-[0] pt-[20px]">
              <Link
                to="/privacy-policy"
                className="text-start md:text-[20px] md:leading-[34px] font-[400] text-[16px] leading:[22px] mont no-underline text-white"
              >
                Privacy Policy
              </Link>
              <Link
                to="/tc"
                className="text-start md:text-[20px] md:leading-[34px] font-[400] text-[16px] leading:[22px] mont no-underline text-white"
              >
                Terms & Condition
              </Link>
              <Link
                to="/refund-policy"
                className="text-start md:text-[20px] md:leading-[34px] font-[400] text-[16px] leading:[22px] mont no-underline text-white"
              >
                Refund Policy
              </Link>
            </div>
          </div>
        </div>

        <div className="self-stretch flex flex-row items-center justify-center text-sm my-5 ">
            <div className="relative z-[1] text-center text-[14px] lading-[17px] mont font-[400]">
              {`© Caro NFT . All Rights Reserved `}
              <span className="">
                {" "}
                | Design & Developed By <a className="text-white" href="https://colladome.com/" target="_blank"> Colladome </a>
                <a className="text-[5px] text-[#0E133D] select-text" href="https://zetawiz.com/">zetawiz</a>
              </span>
            </div>
          </div>

      </div>
    </div>
  );
}

export default Footer;
