import React from "react";

function HowToPlay() {
  return (


    <div className="flex flex-col w-full items-center gap-[72px] px-[18px] xl:px-[120px] pt-[64px] bg-[#050b12] bg-[url('/public/bg-2@2x.png')] bg-cover text-white">
            <div className="max-w-[1180px] w-full mx-auto">
      <div data-aos="fade-up" data-aos-duration="1000"  className="md:py-[60px] md:mb-[0] mb-[0px] py-[20px] text-center mont text-white italic font-[700] leading-[30.04px] font-Montserrat text-[30.04px] md:leading-[66px] md:text-[44px]">
        How To Play
      </div>


      <div className="flex flex-row gap-[32px] md:gap-[50px] h-[250px] md:h-[325px] justify-between md:mb-[80px] ">
        <img data-aos="fade-right" data-aos-duration="1500"
          src="/img/how-one.svg"
          alt=""
          className="h-[200px] md:h-[325px] w-[320px] md:w-[415px] ct-how-img"
        />
        <div className="flex text-start justify-center h-full flex-col" data-aos="fade-left" data-aos-duration="1500" >
            <div className="how-border">
          <h2 className="mont font-[400] m-0 leading-[20.04px] font-bold  font-Montserrat text-[20.04px] md:leading-[36px] md:text-[30px] lg:leading-[36.04px] lg:text-[30px] xl:leading-[36px] xl:text-[30px]">
            {`1. Sign Up & Verify`}
          </h2>
          <p className="mt-3 font-[400] m-0 leading-[20.04px]  font-Montserrat text-[16.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[23.04px] lg:text-[22.04px] xl:leading-[40.13px] xl:text-[24px] ">
            Sign up in our app and verify your Profile.
          </p>
          </div>
        </div>
      </div>


      <div className="flex flex-row gap-[32px] md:gap-[40px] h-[250px] md:h-[325px] justify-between mb-[20px] md:mb-[80px]">
        <div className="flex text-start justify-center h-full flex-col" data-aos="fade-right" data-aos-duration="2000">
        <div className="how-border" >
        <h2 className="mont font-[400] m-0 leading-[20.04px] font-bold  font-Montserrat text-[20.04px] md:leading-[36px] md:text-[30px] lg:leading-[36.04px] lg:text-[30px] xl:leading-[36px] xl:text-[30px]">
            {`2. Select a Match`}
          </h2>
          <p className="mt-3 font-[400] m-0 leading-[20.04px]  font-Montserrat text-[16.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[23.04px] lg:text-[22.04px] xl:leading-[40.13px] xl:text-[24px] ">
            Select Any Match Which You Want To Play From Upcoming Sections.
            {/* <br /> */}
          </p>
          </div>
        </div>


        <img data-aos="fade-left"  data-aos-duration="2000"
          src="/img/how-second.png"
          alt=""
          className="h-[200px] md:h-[347px] w-[320px] md:w-[575px] ct-how-img"
        />
      </div>



      <div className="flex flex-row gap-[32px] md:gap-[100px] h-[250px] md:h-[325px] justify-between mb-[20px] md:mb-[80px]">
        <img data-aos="fade-right" data-aos-duration="1500"
          src="/img/how-three.png"
          alt=""
          className="h-[200px] md:h-[325px] w-[320px] md:w-[415px] ct-how-img"
        />
        <div className="flex text-start justify-center h-full flex-col"  data-aos="fade-left" data-aos-duration="1500">
        <div className="how-border" >
        <h2 className="mont font-[400] m-0 leading-[20.04px] font-bold  font-Montserrat text-[20.04px] md:leading-[36px] md:text-[30px] lg:leading-[36.04px] lg:text-[30px] xl:leading-[36px] xl:text-[30px]">
            {`3. Join Contest`}
          </h2>
          <p className="mt-3 font-[400] m-0 leading-[20.04px]  font-Montserrat text-[16.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[23.04px] lg:text-[22.04px] xl:leading-[40.13px] xl:text-[24px] ">
            Join Any Of The Given Contest Which You Want.
            <br />
          </p>
          </div>
        </div>
      </div>



      <div className="flex flex-row gap-[32px] md:gap-[40px] h-[250px] md:h-[420px] justify-between mb-[20px] md:mb-[80px]">
        <div className="flex text-start justify-center h-full flex-col" data-aos="fade-right" data-aos-duration="1500">
        <div className="how-border"  >
        <h2 className="mont font-[400] m-0 leading-[20.04px] font-bold  font-Montserrat text-[20.04px] md:leading-[36px] md:text-[30px] lg:leading-[36.04px] lg:text-[30px] xl:leading-[36px] xl:text-[30px]">
            {`4. Create Your Team`}
          </h2>
          <p className="mt-3 font-[400] m-0 leading-[20.04px]  font-Montserrat text-[16.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[23.04px] lg:text-[22.04px] xl:leading-[40.13px] xl:text-[24px] ">
            Create Your Own Wining Team By Use Your Excellent Skills Of Sports.
          </p>
          </div>
        </div>
        <img data-aos="fade-left" data-aos-duration="1500"
          src="/howtoplay4.png"
          alt=""
          className="h-[250px] md:h-[400px] w-[320px] md:w-[369px] ct-how-img"
        />
      </div>



      <div className="flex flex-row gap-[32px] md:gap-[100px] h-[250px] md:h-[325px] justify-between mb-[20px] md:mb-[80px] md-md-[100px]">
        <img data-aos="fade-right" data-aos-duration="1500"
          src="/img/how-five.png"
          alt=""
          className="h-[200px] md:h-[325px] w-[320px] md:w-[415px] ct-how-img"
        />
        <div className="flex text-start justify-center  h-full flex-col" data-aos="fade-left" data-aos-duration="1500">
        <div className="how-border">
        <h2 className="mont font-[400] m-0 leading-[20.04px] font-bold  font-Montserrat text-[20.04px] md:leading-[36px] md:text-[30px] lg:leading-[36.04px] lg:text-[30px] xl:leading-[36px] xl:text-[30px]">
            {`5. Attach Your NFT`}
          </h2>
          <p className="mt-3 font-[400] m-0 leading-[20.04px]  font-Montserrat text-[16.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[23.04px] lg:text-[22.04px] xl:leading-[40.13px] xl:text-[24px] ">
            Buy or rent a NFT to attach it with your team.
          </p>
        </div>
        </div>
      </div>


      <div className="flex flex-row gap-[32px] md:gap-[40px] h-[250px] md:h-[400px] justify-between mb-[20px] md:mb-[80px]">
        <div className="flex text-start justify-center h-full flex-col" data-aos="fade-right" data-aos-duration="1500">
        <div className="how-border">
        <h2 className="mont font-[400] m-0 leading-[20.04px] font-bold  font-Montserrat text-[20.04px] md:leading-[36px] md:text-[30px] lg:leading-[36.04px] lg:text-[30px] xl:leading-[36px] xl:text-[30px]">

            {`6. Compete and Win`}
          </h2>
          <p className="mt-3 font-[400] m-0 leading-[20.04px]  font-Montserrat text-[16.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[23.04px] lg:text-[22.04px] xl:leading-[40.13px] xl:text-[24px] ">
            Compete with other palyers and win rewards
          </p>
        </div>
        </div>
        <img data-aos="fade-left" data-aos-duration="1500"
          src="/howtoplay6.png"
          alt=""
          className="h-[250px] md:h-[419px] w-[320px] md:w-[400px] ct-how-img"
        />
      </div>
      </div>
    </div>




  );
}

export default HowToPlay;
