import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import GroupComponent from "./GroupComponent";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import images from '../playersCardDetails';

function NFTcards() {

  return (
    <Swiper cssMode={true}
              navigation={true}
              slidesPerView={2}
              direction={'horizontal'}
              freeMode={true}
              scrollbar={true}
              spaceBetween={20}
              mousewheel={true}
              keyboard={true}
              loop={true}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className=" md:px-[80px] "
            >
            {images.map((item) => {
            return <SwiperSlide >
              <Link to={{pathname: `/card-detail-page/${item.id}`}} key={item.id}>
                <img src={item.img} alt={item.id} className='w-full h-full'/>
              </Link>
            </SwiperSlide>
            })}
            </Swiper>
  )
}

export default NFTcards

{/* 
<Swiper cssMode={true}
              navigation={true}
              slidesPerView={2}
              direction={'horizontal'}
              freeMode={true}
              scrollbar={true}
              spaceBetween={20}
              mousewheel={true}
              keyboard={true}
              loop={true}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className=" px-10 xs:px-28 md:px-16 mySwiper lg:px-20 2xl:px-40 overflow-y-hidden mySwiper"
            >
            <SwiperSlide><GroupComponent captain="Csaptain" /></SwiperSlide>
            <SwiperSlide><GroupComponent captain="Csaptain" /></SwiperSlide>
            <SwiperSlide><GroupComponent
              captain="Vice Captain"
              propLeft="calc(50% - 235px)"
              propWidth="100%"
              propLineHeight="100%"
            /></SwiperSlide> 
            <SwiperSlide><GroupComponent
              captain="Batsman"
              propLeft="calc(50% - 235px)"
              propWidth="100%"
              propLineHeight="33px"
            /></SwiperSlide>
            </Swiper>
  )
}
*/}