import Footer from "../components/Footer";
import Nav from "../components/Nav";

const HowToPlay = () => {
  return (
    <>
      <div className="w-full relative bg-gray-200 overflow-hidden flex flex-col items-center justify-start pt-10 px-0 box-border gap-[173px_0px] tracking-[normal] mq1125:gap-[173px_0px] mq800:gap-[173px_0px] pb-11">
        <img
          className="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/bg@2x.png"
        />
        <section className="w-[1720px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[11px_0px] max-w-full shrink-0 text-justify text-xl text-cold-grey-white font-montserrat">
          <Nav activePage= "how-to-play"/>
          <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[69px] box-border max-w-full text-left text-45xl">
            <h2  data-aos="fade-up" data-aos-duration="1000" className="m-0 relative text-inherit uppercase font-normal font-inherit z-[1] mq450:text-19xl mq800:text-32xl">
              HOW TO PLAY
            </h2>
          </div>
          <div className=" w-full py-0 px-6 md:px-32">
            <div className="self-stretch flex flex-col items-start justify-start py-0 gap-[11px_0px]">
              <div className="self-stretch relative z-[1] mq450:text-base">
                <div>
                  <div>
                    <b>How to Play Caro NFT Cricket Collectible Game</b>
                  </div>
                  1. The user will create a team of their best playing 11.
                  <br />
                  2. Players need 6 NFTs (captain, vice-captain, wicket-keeper,
                  batsman, bowler, all-rounder) to enter the contest. <br />
                  3. Attach 6 NFTs with your playing 11 teams (WC/BAT/ALL
                  ROU/BOW), 4 NFT minimum.
                </div>
                <br />
                <div>
                  <div>
                    <b>How to get NFTs?</b>
                  </div>
                  1. You can buy NFTs from the Caro NFT marketplace. <br />
                  2. You can win NFTs by participating in daily free contests or
                  through various promotional programs like (airdrop, referral
                  programs, affiliate programs, content creator contests) Etc.
                </div>
                <br />
                <div>
                  <div>
                    <b>How can we benefit as winners if not winning games?</b>
                  </div>
                  1. Users can trade, rent, or share their NFTs and earn through
                  this unique concept. <br />
                  2. NFTs can earn reward points for contest participation,
                  which users can use to upgrade NFTs. As NFT levels increase,
                  the value of NFT cards will rise.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className=" w-full relative bg-gray-200 overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border tracking-[normal] text-right text-17xl text-cold-grey-white font-montserrat"
        style={{ zoom: "0.8" }}
      >
        <Footer />
      </div>
    </>
  );
};

export default HowToPlay;
