import React from "react";
import { Link } from "react-router-dom";

const Download = () => {
  return (
    <section id="banner" className="down bg-gray-900 banner">
      <div className="container mx-auto sm:py-8 py-2 px-4">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-5/12 text-center md:text-left">
            <div className="flex items-center justify-start md:justify-start mt-4 md:mt-0 space-x-4 pos-ab">
              <img className="w-16" src="/img/carlo-logo.svg" alt="Logo" />
              <h2 className="sub-heading font-yellow">CaroNFT</h2>
            </div>
            <div className="mt-4 full-w mobile-sec">
              <div className="flex flex-row md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
                <img className="w-full md:w-auto bonus-img1" src="/img/mo1.png" alt="bonus" />
                <img className="w-full md:w-auto mob-2" src="/img/mo2.png" alt="bonus" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-7/12 mt-8 md:mt-0 text-center sm:ps-[60px]">
            <Link  className="hover-none" to="/">
              <h1 className="main-heading text-white fo-500 text-center mb-0">
                Download our Mobile App Now
              </h1>
            </Link>
            <h4 className="text-white mt-5 sub-heading-small font-normal">
              Compete with Other <span className="font-yellow"><b> CaroNFT</b></span> Players<br />
              for Big Rewards.
            </h4>
            <div className="flex items-center justify-center space-x-4 mt-5 mb-5">
              <a target="_blank" href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link"  className="hover-none">
                <img src="/img/play.svg" alt="playstore" />
              </a>
              {/* <a target="_blank" href="https://install.appcenter.ms/users/caronft/apps/caro-nft/distribution_groups/public"  className="hover-none">
                <img src="/img/apple.svg" alt="applestore" />
              </a> */}
            </div>
          </div>  
        </div>
      </div>
    </section>
  );
};

export default Download;
