import Footer from "../components/Footer";
import Nav from "../components/Nav";

const AboutUs = () => {
  return (
    <>
    <div className="w-full relative bg-gray-200 overflow-hidden flex flex-col items-center justify-start pt-10 px-0 box-border gap-[173px_0px] tracking-[normal] mq1125:gap-[173px_0px] mq800:gap-[173px_0px] pb-11">
      <img
        className="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/bg@2x.png"
      />
      <section className="w-[1720px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[11px_0px] max-w-full shrink-0 text-justify text-xl text-cold-grey-white font-montserrat">
        <Nav activePage = "about-us"/>
        <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[69px] box-border max-w-full text-left text-45xl">
          <h1 className="m-0 relative text-inherit uppercase font-normal font-inherit z-[1] mq450:text-19xl mq800:text-32xl">
            ABOUT US
          </h1>
        </div>
        <div className=" w-full py-0 px-6 md:px-32">          
        <div className="self-stretch flex flex-col items-start justify-start py-0 gap-[11px_0px]">
          <div className="self-stretch relative z-[1] mq450:text-base">
            <div>The Caro NFT App is a cricket collectible game that operates on blockchain technology. We devised the idea in early July 2021 after three years of hard work. We focused on developing an innovative product and an excellent concept for the WEB3 cricket collectible gaming industry. At Caro NFT App, we prioritise building long-term relationships with our community, putting our customers first, and making them happy. Our application offers exclusive and user-friendly features that help you improve your gaming skills and win real-time amazing rewards. It is easy to use and available on digital platforms, allowing you to select players and create your playing11 teams. You have the freedom to choose players at your fingertips. Our app includes on-off push notifications, suggested/available user team names, and the unique, most innovative cricket collectible NFT gaming concept. 
            </div>
            <br />
            <div>
            We prioritise fair play and provide a safe and secure platform for users to enjoy. Meta Oryx Online Gaming Services LLC UAE owns the Caro NFT App. Join the fastest-growing cricket collectible gaming platform and experience the thrill of real fantasy cricket at your fingertips. Get started today! Millions of users worldwide play online fantasy games based on different sports, including football, cricket, and soccer. However, there are limitations based on geographical locations. We have noticed that most people want to play their favourite online cricket fantasy sports, but many players are not participating because of the fear of losing. In most gaming applications, players can either win or lose, with a ratio of 70% losing and 30% winning. To address this issue, we developed a concept where everyone can win, and no one will lose in the game. The player will be free from the idea of entry fees to participate in the game contests every time they play games. In this concept, all users will benefit from participating in the game. They will win rewards, or if they can't beat a reward, they will get rewarded in the form of reward points for participating in the game. Users can use these points to upgrade or increase the value of their NFTs (virtual assets).
            </div>
            <br />
            <div> 
            Using blockchain and NFT, we developed a global crypto and NFT-based gaming platform where everyone can play Fearless 7 limitlessly by using CARO NFT. This will increase the prize pool and players' interest, allowing them to earn more through their skills. We aim to become a market leader in the cricket collectible NFT gaming industry. We aim to build a self sustainable decentralized gaming ecosystem to bring more players into the cricket collectible NFT gaming ecosystem. This ecosystem will empower the players, creators, and affiliate partners for their contributions.
            </div>
          </div>
          {/* <div className="self-stretch relative z-[1] mq450:text-base">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div> */}
        </div>
        {/* <div className="w-[1656px] relative inline-block max-w-full z-[1] mq450:text-base">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </div> */}

        </div>
      </section>
    </div>
    <div className =" w-full relative bg-gray-200 overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border tracking-[normal] text-right text-17xl text-cold-grey-white font-montserrat" 
     style={{zoom: "0.8"}}>
        <Footer />
    </div>
        </>
  );
};

export default AboutUs;
