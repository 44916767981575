import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import VideoCard from "./VideoCard";
import ReactPlayer from "react-player/lazy";
import thumb4 from '../assests/video/tun-four.jpg';
import thumb3 from '../assests/video/thumb-3.jpg';
import thumb2 from '../assests/video/comingapp.jpg';
import thumb1 from '../assests/video/one-tumb.jpg';

const videos = [
  {
    id: 1,
    video: "https://youtu.be/hlPfbbl31S0?si=bUzXaTo7eGkkAbPy",
    thumb: thumb2,
  },
  {
    id: 2,
    video: "https://youtu.be/Ict-NpSPrpU?si=UXNoZRth78nNMDa5",
    thumb:thumb4,
  },
  {
    id: 3,
    video: "https://youtu.be/NmjAYgiVdMY",
    thumb: thumb1,
  },
];

function WatchUs() {
  const [coverScreen, setCoverScreen] = useState({
    state: false,
    videoLink: "",
  });

  return (
    <div className="bg-[#050b12] bg-[url('/public/bg-2@2x.png')] bg-cover">
      <div className="max-w-[1450px] md:w-full w-[90%] mx-auto md:pt-[60px] pt-[60px]">
        {coverScreen.state && (
          <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center">
            <div className="relative w-[85%] h-[85%] bg-white">
              <button
                onClick={() => setCoverScreen({ state: false, videoLink: "" })}
                className="cursor-pointer absolute right-0 top-0 text-[45px] z-[4]"
              >
                X
              </button>
              <ReactPlayer
                url={coverScreen.videoLink}
                playing
                controls
                width="100%"
                height="100%"
                volume={0.5}
              />
            </div>
          </div>
        )}
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="md:py-[40px] md:pb-[40px] pb-[40px] text-center mont text-white italic font-[700] leading-[30.04px] font-Montserrat text-[30.04px] md:leading-[66px] md:text-[44px]"
        >
          Watch Us
        </div>
  
        <Swiper
          data-aos="fade-up"
          data-aos-duration="1500"
          cssMode={true}
          navigation={true}
          slidesPerView={1}
          direction={"horizontal"}
          mousewheel={true}
          keyboard={true}
          centeredSlides={false}
          loop={false}
          spaceBetween={20}
          breakpoints={{
            375: { slidesPerView: 1, spaceBetween: 15 },
            467: { slidesPerView: 1, spaceBetween: 15 },
            561: { slidesPerView: 1, spaceBetween: 15 },
            700: { slidesPerView: 2, spaceBetween: 20 },
            900: { slidesPerView: 2, spaceBetween: 20 },
            1200: { slidesPerView: 3, spaceBetween: 20 },
            1400: { slidesPerView: 3, spaceBetween: 20 },
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="watch px-2 md:px-32 h-[80%] lg:h-[80%] lg:w-[100%] md:pb-[100px] pb-[60px] mySwiper"
        >
          {videos.map((item) => (
            <SwiperSlide
              onClick={() =>
                setCoverScreen({ state: true, videoLink: item.video })
              }
              key={item.id}
            >
              <VideoCard image={item.thumb} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default WatchUs;
