import React, { useEffect, useState } from 'react'
import { Button } from "@mui/material";
import MobileNav from './MobileNav';
import { Link } from 'react-router-dom';
import hamburgerIcon from "../icons/Vector.png";
import apkFile from "../assests/app-release.apk"


function Nav(props) {
  const handleDownload = () => {
    const apkUrl = apkFile
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'caro11-app.apk';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const activePage = props?.activePage || "";
        useEffect(() => {
        addEventListener("resize", () => {
        setScreenWidth(window.innerWidth);

        return () => removeEventListener("resize", () => {
            setScreenWidth(window.innerWidth);
        });
        }); }, [screenWidth]);
    
    const classes = "relative tracking-[1px] leading-[21px] uppercase font-medium whitespace-nowrap no-underline text-white"
    const classActive = "relative tracking-[1px] leading-[21px] uppercase font-medium whitespace-nowrap no-underline text-d9d9d9"

    return (
        <>
            {openMobileMenu && <MobileNav setOpenMobileMenu={setOpenMobileMenu} />}
                <header className="w-full flex flex-row items-center justify-between gap-[20px] z-[1] text-left text-base text-cold-grey-white font-montserrat ">
                <Link to={'/'} >
                <img
                
                className="h-[70px] w-[66px] relative ml-[27.18px] md:ml-[120px] overflow-hidden shrink-0 object-cover"
                loading="eager"
                alt=""
                src="/header--link--imagelogopng@2x.png"
                />
                </Link>

                <div className="flex flex-row items-center justify-start gap-[0px_69px] max-w-full mq450:gap-[0px_69px] mq925:gap-[0px_69px] mr-[27.18px] md:mr-[120px] ">
                  {screenWidth > 1100? <><div> <Link to={'/'} className={activePage === '' ? classActive : classes}>
                    Home
                    </Link>
                  </div>
                  <Link to={'/about-us'} className={activePage === 'about-us' ? classActive : classes}>
                    About Us
                  </Link>
                  <Link to={'/how-to-play'} className={activePage === 'how-to-play' ? classActive : classes}>
                    How To Play
                  </Link>
                  <Link to={'/refer-&-earn'} className={activePage === 'refer-&-earn' ? classActive : classes}>
                    refer & earn
                  </Link>
                  <a href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link " target="_blank">
                  <Button className="rounded-81xl [background:linear-gradient(92.23deg,_#cca017,_#38427d_58%,_#171b34)] flex flex-row items-center justify-center pt-4 pb-[15px] pr-[26px] pl-[29px] whitespace-nowrap text-center">
                    <div className="relative tracking-[0.6px] leading-[22.5px] no-underline text-[#ffffff]">
                      Download App
                    </div>
                  </Button>
                  </a>
                  </> : <>
                  <a href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link " target="_blank">
                  <Button className="rounded-81xl [background:linear-gradient(92.23deg,_#cca017,_#38427d_58%,_#171b34)] flex flex-row items-center justify-center pt-4 pb-[15px] pr-[26px] pl-[29px] whitespace-nowrap text-center">
                    <div className="relative tracking-[0.6px] leading-[22.5px] no-underline text-[#ffffff]">
                      Download App
                    </div>
                  </Button>
                  </a>
                  <div className="relative ">
                    <button  style={{backgroundColor: 'transparent'}} onClick={() => setOpenMobileMenu(true)}>
                        <img className=" w-6 h-6" src={hamburgerIcon} />
                    </button>
                  </div>
                  </>
                    }
                </div>
                
              </header>
              </>
  )
}

export default Nav