import Footer from "../components/Footer";
import Nav from "../components/Nav";

const TC = () => {
  return (
    <>
    <div className="w-full relative bg-gray-200 overflow-hidden flex flex-col items-center justify-start pt-10 px-0 pb-0 box-border gap-[173px_0px] tracking-[normal] mq1125:gap-[173px_0px] mq800:gap-[173px_0px]">
      <img
        className="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/bg@2x.png"
      />
      <section className="w-[1720px] z-20 flex flex-col items-center justify-start py-0 px-5 box-border gap-[11px_0px] max-w-full shrink-0 text-justify text-xl text-cold-grey-white font-montserrat">
        <Nav />
        <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[69px] box-border max-w-full text-left text-45xl">
          <h1 className="m-0 relative text-inherit uppercase font-normal font-inherit z-[1] mq450:text-19xl mq800:text-32xl">{`Terms & Conditions`}</h1>
        </div>
        <div className=" w-full py-0 px-0 md:px-32">
        <div className=" flex flex-col items-start justify-start gap-[11px_0px]">
          <div>
          CARONFT.COM is a platform owned and operated by Meta Oryx Online Gaming Services LLC,
          which is registered in UAE. The platform enables users to acquire digital cricket collectibles NFT
          or collectibles through purchase or auction. CARONFT.COM operates web applications,
          services, and platforms and may also add mobile applications in the future. We reserve the right
          to change our terms at any time, and any updates will be posted on our website at
          www.caronft.com. Users agree to any subsequent changes in our terms by agreeing to the
          present terms. If you have any issues or questions, please get in touch with us at <a style={{color:"#cca016"}} href="mailto:support@CARONFT.COM">support@CARONFT.COM</a>.
          </div>
          <div>
          CARONFT.COM is a platform that serves as a NFT Cricket collectible Gaming Platform. The
          platform allows users to acquire digital collectibles through purchases or auctions facilitated by
          the platform and sellers. However, it's important to note that CARONFT.COM is not responsible
          for the secondary trade of NFTs and acts only as an intermediary between the seller and the
          user.
          </div>
          <div>
          CARONFT.COM is not a broker, financial institution, exchange, creditor, custodian, or traditional
          auction platform. Instead, it functions as an NFT Gaming platform with no warranty or guarantee
          for winning or profiting through playing games, buying, or selling NFTs.
          </div>
          <div>
          It's also important to note that CARONFT.COM will not be held liable for any misleading
          representations made by individuals or affiliates' claims to the platform's users
          </div>
          <div>
          By accessing the platform, you acknowledge that CARONFT.COM grants you a personal, nonexclusive, non-transferable, non-sublicensable, revocable, limited right to access the platform,
          which shall be solely used in compliance with the terms and conditions provided herein and to
          the extent permitted under applicable laws, rules, and regulations.
          </div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Disclaimer</h3> 
        <div>
        We reserve the right to change the format and content of the Platform periodically. Your use of
        the Platform is entirely at your own risk and "as is" and "as available". While we strive to ensure
        that all information on the Platform is accurate, it is not intended to be authoritative or relied
        upon as advice. Before acting on any information, verify it with us or the relevant source.
        </div>
        <div>
        We make no representation or warranty as to the accuracy, completeness, currency, correctness,
        reliability, integrity, quality, availability, fitness for purpose or originality of any content of the
        Platform. All implied warranties, conditions or other implied terms of any kind are excluded to
        the fullest extent permitted by law, and we accept no liability for any loss or damage incurred by
        you or anyone else using the Platform or relying on its content.
        </div>
        <div>
        We cannot guarantee that any content on the Platform will be free from viruses or other
        destructive elements. You are responsible for implementing appropriate IT security measures,
        including anti-virus and other security checks, to ensure the content's safety and reliability.
        We are not liable for any losses, including indirect or consequential losses, or any loss of profit,
        revenue, contracts, data, goodwill, or other similar losses. Our liability for losses is strictly limited
        to reasonably foreseeable losses.
        </div>
        <div>
        We are not responsible for user errors, incorrect data, or loss of NFTs or other information.
        Collectables are intangible assets represented by the corresponding NFT on the Ethereum
        network. We are not responsible for the network, recording, or verifying ownership or value of
        any Collectible.
        </div>
        <div>
        Our role is limited to providing a Platform for users to offer and buy Collectibles to play games.
        Except for the initial sale of each Collectible, we are not a party to any secondary sale or
        purchase. We do not take payment on behalf of other parties and are not a payment service
        provider.
        </div>
        <div>
        While we strive to ensure that all Collectibles are original works and do not infringe any third
        party rights, You have no recourse or rights against us about the existence of similar or identical
        works or the infringement of any third-party rights.
        </div>
        <div>
        Each User is responsible for defending, indemnifying, compensating, reimbursing, and
        preserving the innocence of CARONFT.COM Company (including all officers, directors,
        members, employees, sellers, and affiliates) from any claim, demand, action, damage, loss,
        value, or expense, including reasonable attorney's fees, arising out of or related to (a) the User's
        use of or behaviour on the Platform, (b) the User's violation of these Terms or any other relevant
        policy or agreement of CARONFT.COM Company, or (c) the User's violation of any rights of third
        parties.
        </div>
        < h3 className="my-3 text-primary fs-18 font-weight-bold">Platform Access</h3>
        <div>
        The platform is intended for users at least 18 years of age or who meet the minimum legal age
        in their jurisdiction, whichever is later. Users who meet these criteria can enter into a contractual
        agreement with CARONFT.COM.
        </div>
        <div>
        By accessing the platform, you agree that you can legally use it and its features. You also agree
        not to use the platform for illegal activities, including acts of terror or activities that could harm
        the security and legal interests of any individual or country/administrative body.
        </div>
        <div>
        By accessing the platform, you confirm that you are not on any of the following lists: European
        External Action Service Consolidated Financial Sanctions List; EU Terrorist List; FATF NonCooperative Countries and Territories; Federal Bureau of Investigation Most Wanted Terrorists &
        Seeking Information; Bank of England Sanctions List; HM Treasury Sanctions List; Politically
        Exposed People List; World Bank Ineligible Firms; Department of Foreign Affairs and Trade
        Consolidated List.
        </div>
        <div>
        By accessing the platform, you are responsible for ensuring that your equipment meets the
        technical requirements needed to access the platform. We are not responsible for any harm or
        loss caused by inadequate or malfunctioning equipment.
        </div>
        <div>
        By accessing the platform, you agree that we can restrict your access for security reasons. You
        are responsible for keeping your login information secure and confidential, including usernames
        and passwords. Any activity under your login is your responsibility, and you must report any
        unauthorised access immediately.
        </div>
        <div>
        By accessing the platform, you agree that all information you provide is accurate, and you are
        responsible for addressing any issues that may arise due to inaccurate information. You may
        customise your username, but we reserve the right to change it if it includes profanity or hate
        speech.
        </div>
        <div>
        By accessing the platform, you agree that we may deactivate your account and access if you
        violate the terms and conditions. We are not responsible for any actions outside or within the
        platform that result in your account being disabled.
        </div>
        <div>
        By accessing and using the services and the platform, you represent and warrant that you are
        using our services for hobby and private use only and not for any business purposes. Any
        activity undertaken by a person or business entity is for recreational purposes only, not for
        furthering any business purposes. The services are provided to natural persons for self-use, and
        no business entities may avail of the services.
        </div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Third Parties</h3>
        
        <div>As part of our operations, we may collaborate with third-party service providers to handle
        payment processing, custody management, and completion of KYC (Know Your Customer)
        formalities. Complete KYC/AML formalities must be completed to access the funds received
        from selling your NFT. A third-party provider will manage the KYC/AML formalities, and their
        terms and conditions will govern the process. Please note that CARONFT.COM is not
        responsible for any issues that may arise during the KYC/AML process.</div>
        <div> We may engage with different payment service providers to process your payments. By using
        these payment services, you agree to their terms and conditions, and their terms of service will
        govern the entire payment process. While we will provide all possible assistance about
        payments, we are not responsible for any issues or disputes that may arise due to payment
        processing.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Binding Agreement</h3>
        <div>These terms and conditions ("Terms") govern your usage of the CARONFT.COM platform
        ("Platform") as a user ("User") who engages in trading of non-fungible tokens representing
        digital artwork, audio, images, games, and any other content that might be added to the
        Platform in the future ("Service" or "Services").</div>
        <div>A "Digital Collectible(s)" or "Collectible(s)" is a non-fungible token implemented on the Binance
        blockchain platform using smart contracts ("Smart Contracts" or "Byte Codes").</div>
        <div>Suppose you're using the platform for a company or any other organisation ("entity"). In that
        case, you confirm that you're fully authorised to bind such an entity to the warranties and
        obligations made under this agreement. You also represent and warrant that you have the right,
        authority, and capacity to enter into a disagreement on behalf of that entity.</div>
        <div>By accessing or using the Platform, you agree to these Terms. If you disagree with these Terms,
        you should not access or use the Platform.</div>
        <div>Using the Platform (including applying for or opening a CARONFT.COM account) doesn't
        automatically mean that you'll be entitled to enjoy full use of the Platform's functionality
        (including bidding for NFTs at auction or purchasing NFTs) and that we'll enter into any form of
        commercial or other arrangement with you, other than this Agreement.</div>
        <div>All the terms mentioned in this Agreement are intended to continue. They shall survive
        termination, including an Agreement relating to exclusions and limitations of liability, intellectual
        property restrictions, and ongoing use of your data.</div>
        <div>Please note that we reserve the right to collaborate with third-party service providers without
        prior notice. By agreeing to our terms, you also agree to all our present and future third-party
        engagements. Each third-party service provider may have its terms and conditions, which you
        must agree to. Any disputes arising between you and the third-party service provider should be
        settled between the parties involved, as the platform is not responsible for resolving such
        conflicts.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Refund Policy</h3>
        <div>Refunds: <br />
        Under no circumstances will any form of refund be entertained, especially for completed
        transactions. The platform is not responsible for wrongly conducted transactions, transactions
        carried out with wrong addresses, transactions carried out due to any malicious link/website/
        app/media/claim in any form impersonating the platform, and transactions attempted without
        complying with the set standards.</div>
        <div>Funds in Wallet: <br />
        The funds added to your wallet will become available for withdrawal nine days after the
        completion of the drop.</div>
        <div>Digital Collectible Transactions: <br />
        Under no circumstances will a completed transaction for the purchase of a Digital Collectible be
        reversed or refunded.</div>
        <div>Lost Auctions: <br />
        The bid amount will be returned to your Wallet if you lose an auction. However, the ninth-day
        withdrawal interval still applies.</div>
        <div>Active Auctions: <br />
        If you have placed a bid and the auction is still active, your Wallet balance will not be eligible for
        withdrawal.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Refund Rules</h3>
        <div>The platform reserves the right to modify the rules of refunds, and it will be updated in the terms
        and conditions. Please check the Terms page for the latest version of the rules and policies.</div>
        <div>Different Selling Formats for Launchpad: <br />
        Digital collectibles are available for direct purchase, pre-booking, auction, or limited order. If
        you purchase a Digital Collectible at an advertised list price, your spending balance will be
        permanently reduced.</div>
        <div>Auction Method: <br />
        The platform follows the classic English auction method. The auction will be active for a specific
        period, during which the Digital Collectible will be listed, and the minimum bid value will be set.
        The highest bidder at the end of the auction will be eligible to own the Digital Collectible,
        subject to meeting the required conditions.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Last Minute Bids:</h3>
        If a bid is placed within the last 10 minutes of the auction, the auction time will be extended by
        10 minutes from the bid, so last-minute bids can be accommodated.
        <div>Unavailable Bidding Amount: <br />
        The bidding amount will be unavailable for spending, other bids, and direct purchases.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Pre-Booking Policy:</h3>
        <div>Pre-booking does not guarantee order confirmation or availability of the product on the day of
        the launch (Drop). The total price of the product(s) will be charged when placing the Pre-book
        order. If CARONFT.COM cannot process partial or complete Pre-book order(s), a partial or full
        refund will be processed within 9-14 working days. The sale is completed once customers are
        allocated their NFTs/collectables, and a confirmation is sent via email to their CARONFT.COM
        account.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">NFT Pricing Policy</h3>
        <div>Please find below the revised text with corrected spelling, grammar, and punctuation errors:</div>
        <div>The seller is responsible for determining the sale price and minimum bid price of the NFT listed
        on the CARONFT.COM platform and for deciding the duration of the bidding window.
        CARONFT.COM is not responsible for the seller’s pricing or any unsold NFT, regardless of the
        reason.</div>
        <div>Although CARONFT.COM determines the initial cost of the NFT at the time of the drop, any
        price increase or decrease is beyond its control. Therefore, CARONFT.COM shall not be held
        responsible for any price fluctuations on the NFT.</div>
        <div>According to the terms and conditions, the seller is responsible for the NFT's usage until it is
        sold. They must ensure the listing is accurate, complete, and not deceptive or misleading. The
        sale listing should provide all relevant information about the NFT.</div>
        <div>The seller acknowledges that they do not own the NFT's intellectual property rights or content,
        except for what has been explicitly mentioned in the limited license granted to them.</div>
        <div>When a prospective bidder places a bid, the seller must acknowledge it within seven days.
        Failure to do so will result in the bid being lapsed, and the price of the NFT may drop. Any price
        changes, including the removal of the NFT from the sale, are the seller's responsibility, and
        CARONFT.COM is not liable for any consequences.</div>
        <div>The seller must not use fake accounts to increase their listing price through false bids and must
        not list their NFT for sale on any other third-party platform except CARONFT.COM. Any such
        activity will be considered a violation of the terms, and CARONFT.COM shall not be held
        responsible for any consequences. Please find below the revised text with corrected spelling,
        grammar, and punctuation errors:</div>
        <div>The seller is responsible for determining the sale price and minimum bid price of the NFT listed
        on the CARONFT.COM platform and for deciding the duration of the bidding window.
        CARONFT.COM is not responsible for the seller’s pricing or any unsold NFT, regardless of the
        reason.</div>
        <div>Although CARONFT.COM determines the initial cost of the NFT at the time of the drop, any
        price increase or decrease is beyond its control. Therefore, CARONFT.COM shall not be held
        responsible for any price fluctuations on the NFT.</div>
        <div>According to the terms and conditions, the seller is responsible for the NFT's usage until it is
        sold. They must ensure the listing is accurate, complete, and not deceptive or misleading. The
        sale listing should provide all relevant information about the NFT.</div>
        <div> The seller acknowledges that they do not own the NFT's intellectual property rights or content,
        except for what has been explicitly mentioned in the limited license granted to them.</div>
        <div>When a prospective bidder places a bid, the seller must acknowledge it within seven days.
        Failure to do so will result in the bid being lapsed, and the price of the NFT may drop. Any price
        changes, including the removal of the NFT from the sale, are the seller's responsibility, and
        CARONFT.COM is not liable for any consequences.
        The seller must not use fake accounts to increase their listing price through false bids and must
        not list their NFT for sale on any other third-party platform except CARONFT.COM. Any such
        activity will be considered a violation of the terms, and CARONFT.COM shall not be held
        responsible for any consequences.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Smart Contracts</h3>
        <div>Thank you for requesting a rewrite of the text. Please find below the revised version of the text
        with corrections made to spelling, grammar, and punctuation errors.</div>
        <div>Please note that the items available on the platform for direct sale or auction are unique
        cryptographic tokens, also known as non-fungible tokens. These tokens are minted using smart
        contracts and are tracked and stored on the Polygon Blockchain.</div>
        <div>The platform is only responsible for the smart contract that constitutes the non-fungible tokens
        launched on CARONFT.COM. The initial owner of the token will be the artist, creator, brand,
        celebrity, or game developer with whom the platform partners.</div>
        <div>Any transaction intended for transferring, offering, bidding, listing, selling, or purchasing these
        non-fungible tokens will be initiated through one or more smart contracts. Some of the elements
        of every transaction might be outside the Polygon ecosystem. By consenting to this agreement,
        you also accept the transfer of Digital Collectibles, fees, commissions, and royalties. Many
        processes, including but not limited to the ones mentioned above, will be automated through
        smart contracts.</div>
        <div>The platform accepts third-party payment service providers, UPI, Apple Pay, bank transfers or
        direct crypto payments (refer to the ‘The Custodial CARONFT.COM Wallet’s section). You may
        also fund your wallet through credit cards, debit cards, and other methods, subject to the
        options provided by the third-party payment processor. Payment restrictions and limits may also
        result from your agreement with the third-party payment service provider or the financial
        institution that provides the payment instrument you utilise to fund your wallet.</div>
        <div>You are responsible for paying all the taxes involved in the transaction, including any taxes that
        might become payable in the future due to your ownership, transfer, purchase, or sale. You are
        also responsible for paying all the Goods and Services Tax on a reverse charge basis or forward
        charge basis as may be applicable.</div>
        <div>Any activity on the blockchain, including transactions, may require payments of small fees called
        ‘gas’ fees. The platform may absorb the gas fees on your behalf or pass them on to you. If the
        platform does not bear any gas fees, it is strictly on a non-precedential basis. The platform may
        also add or deduct gas fees in the future.</div>
        <div>The value of the gas fee fluctuates rapidly and is often unpredictable. It is outside the control of
        the platform. By consenting to this agreement, you acknowledge that no contract, agreement,
        offer, bid, sale, or any other form of transaction will be invalidated, revocable, retractable, or
        otherwise unenforceable on the platform on the basis that the gas fee was unknown or too high
        or unacceptable in any other way to you.</div>
        <div>All gas fees are non-refundable and will not be refunded under any circumstance whatsoever.
        You are responsible for ensuring that the payment instrument stays active during withdrawal.
        CARONFT.COM will not be held responsible for any funds that might have lapsed or gotten lost
        because of faulty, inaccurate, or invalid payment instrument information provided by the user,
        which nullifies or tampers with the processing of the wallet money withdrawal.</div>
        <div>By using the platform, you agree that the applicable artist fee/royalty and the processing/
        service/platform fee will be automatically debited/deducted from the final price of the NFT or
        the final credit amount for selling the NFT (whichever is applicable). The process is automated
        and on-chain; every transaction will have the appropriate fees deducted in relevant instances.</div>
        <div>The platform can modify the transaction platform fee/royalty and the processing/service without
        prior notice. You must check the latest terms and conditions to ensure you are updated. The
        applicable fees might be charged as an absolute or a percentage of the transaction amount,
        which the platform will determine on a case-by-case basis.</div>
        <div>All payments made to the platform for transactions are non-refundable. You can read the details
        of this policy under the Refund Policy section below.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Responsibilities of the Buyer</h3>
        As a buyer, you are responsible for ensuring the accuracy of information and authenticity of the
        NFT you intend to purchase. The platform will not be held responsible for any disputes arising
        from the buyer's negligence or lack of diligence.
        <div>Please note that the NFTs available on CARONFT.COM are exclusively sold on the platform. Any
        purchase made from an unauthorised third-party will not be the platform's responsibility, as
        outlined in the terms and conditions.</div>
        <div>The platform provides specific tools to assist buyers in determining the rarity of the NFT they
        wish to purchase. However, the rarity tool is just an instrument and does not directly determine
        the NFT's price. It is the buyer's responsibility to exercise due diligence in using the tool and in
        understanding its limitations.</div>
        <div>Please note that the website does not constitute financial or investment advice. The algorithmic
        ranking does not imply anything on commercial lines whatsoever.</div>
        <div>The tool should be used exclusively for determining the rarity ranking within the collection and
        not outside the collection. Any losses or damages resulting from such comparison and any
        action taken consequently will not be the responsibility of the tool or the platform.</div>
        <div>As a buyer, you are responsible for the bid you have placed, and you must pay the bid unless a
        higher bid has been placed or the seller has removed the NFT from the listing. Any violation of
        this rule will result in consequences that will be solely the buyer's/bidder's responsibility.</div>
        <div>Engaging in unlawful or unfair practices, such as bidding without a serious intent to buy or
        engaging in anti-competitive bidding activities, including using bots or artificial/fake accounts to
        bid on any listing, is not allowed.</div>
        <div>Please note that upon purchasing an NFT or placing a bid for an NFT, you are bound by the
        terms and conditions that govern the usage of the NFT upon the completion of the sale/closure
        of bidding.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Properties of a Digital Collectible</h3>
        <div>We hold no responsibility for the Collectibles traded on our Platform. We cannot guarantee the
        future value of the Collectible. we have no responsibility for value changes of collectibles that
        may occur occasionally.</div>
        <div>It's important to understand that the value of Collectibles is subjective, volatile, and subject to
        fluctuations, emotions, and market movements. These Collectibles are not legal tender and are
        not backed by any government. Legislative and regulatory changes may adversely affect the
        value, use, and transferability of these Collectibles.</div>
        <div>Our collectibles could be subject to the risk of counterfeiting, fraud, cyber attacks, or technical
        challenges that might prevent your access to them.</div>
        <div>By purchasing or bidding on Collectibles, you agree that you are solely responsible for
        determining the potential value, suitability, nature, and appropriateness of risks. You understand
        that we will not be responsible for any disruptions, errors, distortions, or communication failures
        you may experience while using our services. We do not take responsibility for your buying or
        bidding decisions in any way.</div>
        <div>Upon purchasing or winning an auction, you become the owner of the Digital Collectible. As the
        owner of the Digital Collectible, you have the eligibility and right to sell your Digital Collectible
        only on the proprietary platform.</div>
        <div>The marketplace will have its own set of terms and conditions that both buyers and sellers must
        agree to, in addition to the terms and conditions that govern the platform.</div>
        <div>Once transferred to you, all the risk and responsibility of the Digital Collectible will fall on you.
        The exact time at which the risk of the Digital Collectible is transferred may be determined at the
        platform's discretion.</div>
        <div>It's important to note that the platform is not responsible for any uses you make of the Digital
        Collectible or any future transactions you make with the Digital Collectible. The platform does
        not guarantee any future profit, and purchase is at the user's discretion.</div>
        <div>The value of Collectibles and digital assets is highly volatile and subject to fluctuations,
        emotions, and market movements due to regulatory, statutory, and market changes. You should
        research and understand the risks before purchasing on the platform. The platform does not
        guarantee any profit in any collectibles and digital assets purchased on the platform, and the
        value will be market-driven and dependent on external factors. The platform will not bear any
        liability or indemnify the user in case of any diminution in the value of Collectibles and digital
        assets purchased on the platform.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Intellectual Property of the Platform</h3>
        <div>CARONFT.COM and its licensors own all legal rights, titles and interests in and to all elements of
        the Platform, which includes text, images, graphics, design, systems, methods, information,
        computer code, software, services, 'look and feel', organisation, compilations, data,
        photographs, articles, images, illustrations, audio, audiovisual, video, and all other elements of
        the Platform. All these elements are collectively and individually called "CARONFT.COM
        Materials".</div>
        <div>All trademarks, service marks, and trade names contained in the CARONFT.COM Materials are
        owned by CARONFT.COM or its licensors. Any product names, brand names, trade names,
        logos, service marks, trade dress, slogans, product packaging, and designs that appear on the
        Platform, whether or not registered, are owned exclusively by CARONFT.COM or their
        respective owners (including CARONFT.COM brand partners) and are protected by national and
        international trademark and copyright laws from use, misuse, reproduction, imitation, dilution, or
        confusing or misleading uses.</div>
        <div>Using the Platform, you agree to abide by all copyright notices, trademark rules, information,
        and restrictions contained in any CARONFT.COM Materials featured on or accessed through the
        Platform.</div>
        <div>You are not allowed to use, copy, reproduce, modify, translate, store, publish, broadcast,
        transmit, distribute, perform, upload, display, license, sell, or otherwise exploit for any purpose
        any CARONFT.COM Materials or other proprietary rights not owned by you, (i) without
        CARONFT.COM's express prior written consent and that of any applicable respective rights
        owners, and (ii) in any way that violates any third-party rights.</div>
        <div>You cannot modify, publish, transmit, participate in the transfer or sale of, reproduce (except as
        expressly permitted by this Agreement), create derivative works based on, distribute, perform,
        display, or in any way exploit any of the CARONFT.COM Materials, software, materials, or
        Platform in whole or in part.</div>
        <div>You cannot use modified versions of any software underlying our Platform, including, without
        limitation, to obtain unauthorised access to our Platforms or applications.
        CARONFT.COM reserves all rights in and to the CARONFT.COM Materials not expressly granted
        to you in this Agreement.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Intellectual Property of the NFT</h3>
        <div>As the owner of a Digital Collectible, you are not allowed to copy, modify, edit, or reverse
        engineer the Collectible in whole or in part, including but not limited to its imagery, design,
        colours, look and feel format, features, or attributes.</div>
        <div>As the owner of a Digital Collectible, you are not permitted to use the Collectible in any
        derogatory manner or for any purpose that promotes hatred, violence, racism, slavery,
        intolerance, cruelty to humans and other living beings, or harassment.</div>
        <div>As the owner of a Digital Collectible, you may not use it for sales purposes unless it is explicitly
        authorised by law. You may not use it for gambling, in a pornographic context, for spamming
        purposes, or in any way that intends to infringe on the rights of others and is deemed unlawful.
        This includes any conduct that could be considered defamatory, threatening, abusive, obscene,
        vulgar, offensive, or indecent. You must understand that you will be held liable for any legal
        consequences arising from such actions.</div>
        <div>As the owner of a Digital Collectible, you are not allowed to remove or obscure any signature of
        the creator/brand that attributes the creation. It would be best if you refrained from taking any
        steps that might mislead others or cause discrepancies in identifying the brand/celebrity/creator
        behind the Collectible.</div>
        <div>As the owner of a Digital Collectible, you are not permitted to use the Collectible for any
        commercial purpose, including but not limited to the promotion of businesses, products, or
        services, advertising, creation of commercial products like merchandise, or referring to or
        otherwise linking themselves to the Collectible or any of its attributes.</div>
        <div>Any financial benefit that you derive from the Collectibles, including but not limited to its value
        in secondary marketplaces, is purely incidental. We do not guarantee that the price/value of the
        Collectible will move in a specific direction. You must understand that the Collectible's value is
        subjective and vulnerable to emotions, volatility, market fluctuations, and regulations/legal
        frameworks.</div>
        <div>As the owner of a Digital Collectible, you have the right to use the Collectible to share, promote,
        discuss, or comment on the NFT and your ownership or purchase of it, including on social media
        platforms, blogs, digital galleries, or other digital media. You may also use it on third-party
        marketplaces deemed acceptable by the Platform, exchanges, platforms, or applications in
        association with an offer to sell or trade the NFT to which the Work is affixed. You may also use it
        within decentralised virtual environments, virtual worlds, virtual galleries, virtual museums, or
        other navigable and perceivable virtual environments, including simultaneous display of
        multiple copies of the Works within one or more virtual environments.</div>
        <div>Indemnification <br />
        By agreeing to this Agreement, you release CARONFT.COM and its subsidiaries, affiliates,
        officers, and successors from any liability for direct or indirect copyright or trademark
        infringement resulting from CARONFT.COM's use of a Works by this Agreement. This includes
        any solicitation, encouragement, or request for You or third parties to host the Works to operate
        a distributed database and/or promote the Platform.</div>
        <div>You also agree to indemnify and hold CARONFT.COM, its parents, subsidiaries, affiliates, officers
        and employees harmless from any claim or demand made by any third party. This includes all
        damages, liabilities, settlements, costs, and attorney's fees arising out of or due to Your access to
        the Platform, use of the Platform, the violation of this Agreement by You, or the infringement by
        You, or any third party using Your account, of any intellectual property or other right of any
        person.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Warranties</h3>
        <div>Please take note of the following information. Whenever you engage in any transaction on our
        Platform, your wallet's public address will be visible. We may change the Blockchain that powers
        our Platform. Still, the terms and conditions we have provided will remain applicable regardless
        of any changes in the Blockchain or the decentralised protocol we use.</div>
        <div>Please be aware that Blockchain technology is still in its early stages, and technological
        difficulties may affect the operation of our Platform and your access to your Collectibles.
        Additionally, legal uncertainties exist, and regulatory inquiries, investigations, claims, fines,
        judgments, suits, and actions could adversely impact the Platform, your Collectibles, and your
        funds. By agreeing to our terms and conditions, you acknowledge that you understand these
        risks and uncertainties.</div>
        <div>It is important to note that any transactions involving the purchase of Collectibles may not be
        reversible except as otherwise provided in our refund policy. We cannot be held liable for any
        losses resulting from fraudulent or accidental transactions, and it is the user's responsibility to
        ensure the accuracy of the information.</div>
        <div>Finally, please be aware that the Blockchain that powers our Platform may undergo sudden
        changes in operating rules, resulting in multiple versions of your Collectibles. This may affect the
        value and function of your initial Collectible, and we reserve the right to temporarily suspend
        our services while we determine which network to support. We may also choose to stop
        supporting or abandon the original or copy of the Collectible and obtain and retain the
        unsupported Collectible at our sole discretion.</div>
        <div>If any of the terms or their application is declared illegal, void, invalid, or unenforceable by any
        competent body, the remaining terms' legality, validity, and enforceability will not be affected.
        We cannot guarantee continuous, uninterrupted, and error-free availability and operability of
        our Platform, and we are not liable for any losses you may suffer or any drop in the value of the
        Collectibles you own on our Platform.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Refer And Earn</h3>
        <div>To be eligible for referral bonuses, you must have an account on CARONFT.COM and invite new
        users, referred to as “friends,". The individuals you invite must purchase the caro NFTs drop and
        cannot already have an account on CARONFT.COM.</div>
        <div>To qualify for the "Refer & Earn" bonus, the person(s) you invite must use the unique referral link/
        code provided in your invitation and sign up for CARONFT.COM using an email address that is
        not associated with any other CARONFT.COM. You and the referred person must complete a
        valid KYC verification to receive the referral Bonus.</div>
        <div>Your referral remains valid until the caro NFT drop is made live by CARONFT.COM. There are no
        limitations on the number of people you can invite. However, the person you invite must meet
        all the eligibility criteria and pass verification to become an active user.</div>
        <div>To claim a Referral Bonus, the Referred User must 1) Verify their Email Address and 2) Verify their
        identity by completing the KYC process. To claim a Referral Bonus, the Referring User must 1)
        Verify their Email Address and 2) Verify their identity by completing the KYC process.</div>
        <div>Creating multiple accounts on CARONFT.COM to receive various rewards is not allowed, and
        doing so will result in penalties, including cancellation of referral benefits and account
        suspension.</div>
        <div>To use CARONFT.COM's platform, you must be at least 18 or of minimum legal age in any
        jurisdiction (whichever is later). Any referred sign-ups and purchases that violate the laws of their
        jurisdiction will attract penalties, including cancellation of referral benefits and suspension of the
        referred accounts.</div>
        <div>CARONFT.COM reserves the right to withdraw this bonus offer at any time if you violate any
        conditions of the Terms and Conditions of <a style={{color:"#cca016"}} href="https://www.CARONFT.COM">https://www.CARONFT.COM</a>. The decision of the
        CARONFT.COM management shall be final and binding in case of any disputes. All standard
        CARONFT.COM terms and conditions apply.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Limitation of Liability</h3>
        <div>CARONFT.COM does not take responsibility for errors, mistakes, omissions, or inaccuracies
        about the materials on CARONFT.COM. Additionally, we are not responsible for any loss or
        damage that may occur through the use of or exposure to any CARONFT.COM materials
        posted, emailed, accessed, transmitted, or otherwise made available via the platform.</div>
        <div>Furthermore, CARONFT.COM is not liable for any losses or damages resulting from another
        user's violation of this Agreement, including unauthorised access to or use of our servers and
        other Platform features. We do not offer any restitution or compensation for loss of profits,
        revenues, or data.</div>
        <div>CARONFT.COM will not be liable for any indirect, special, incidental, consequential, punitive, or
        exemplary damages related to participation in or the outcome of platform uses, NFT ownership,
        interactions, and transactions, whether or not CARONFT.COM has been advised or knew of the
        possibility of such damages.</div>
        <div>We are not responsible for the acts or omissions of any third parties, nor for any damage that
        may result from your transactions or any other interaction with any third parties, including thirdparty providers that provide Wallet services and store the NFT.</div>
        <div>Please note that to the fullest extent permitted by law, we will not be liable to you or any third
        party for any lost profit or any indirect, consequential, exemplary, incidental, special, or punitive
        damages arising from these terms, the service, platform, any platform content, or items, or for
        any damages related to loss of revenue, loss of profits, loss of business or anticipated savings,
        loss of use, loss of goodwill, or loss of data, and whether caused by tort (including negligence),
        breach of contract or otherwise, even if foreseeable and even if we have been advised of the
        possibility of such damages.</div>
        <div>You undertake access to and use of the service at your discretion and risk, and you will be solely
        responsible for any damage to your computer system or mobile device or loss of data resulting
        from it.</div>
        <div>Lastly, please note that in no event shall the maximum aggregate liability of CARONFT.COM
        arising out of or in any way related to these terms, your access to and use of the service,
        platform content, or any items sold through the service exceed the greater of (a) $10 or (b) the
        amount paid to CARONFT.COM by you for the service in the transaction or incident that is the
        subject of the claim. If you are dissatisfied with any portion of the platform or with these terms of
        the platform, your sole and exclusive remedy is to discontinue the use of the platform.</div>
        <div>
        Hardware and Equipment <br />
        When using the Platform, you must ensure that your equipment meets the technical
        requirements to access it properly. Please note that we are not responsible for any harm or loss
        that may occur due to your equipment's inadequacy or malfunction.</div>
        <div>
        Data and Privacy  <br />
        To learn how we handle personal information, please refer to our privacy policy at  <a style={{color:"#cca016"}} href="https://www.CARONFT.COM/privacy-policy">https://www.CARONFT.COM/privacy-policy</a>. Using our Platform, you agree to be bound by this
        Agreement, which incorporates the Privacy Policy.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Severability</h3>
        <div>Suppose that any provision of this Agreement is deemed illegal or unenforceable. In that event,
        such provision shall be considered separable from this Agreement and shall not affect the
        enforceability of any other provisions. <br />
        Governing Law and Jurisdiction <br />
        These terms will be governed, construed and interpreted by the laws of the UAE for all matters,
        including validity, construction, effect, enforceability, performance and remedies. Even though
        the offerings may be available in other jurisdictions, each user acknowledges and agrees that
        such availability will not be deemed to give rise to general or specific jurisdiction over
        CARONFT.COM Company in any forum outside the UAE.</div>
        <div>If a User has a potential legal dispute, claim or cause of action against CARONFT.COM
        Company, the User shall first (before initiating any proceedings) contact CARONFT.COM
        Company by sending an email to <a style={{color:"#cca016"}} href="mailto:support@CARONFT.COM">support@CARONFT.COM</a> describing the nature of the
        potential dispute, claim or cause of action and providing all relevant documentation and
        evidence thereof. Users shall use their respective reasonable endeavour to settle any dispute,
        difference claims, question or controversy arising out of, in connection with, or relation to these
        terms ("Dispute") amicably through negotiations in good faith. <br />
        Arbitration <br />
        All disputes arising out of or in connection with the present terms or about or at once or not
        directly regarding those Terms or any of the topics or transactions pondered with the aid of
        using those Terms (for the avoidance of doubt, along with any declare searching for to
        invalidate, or alleging that, any or all a part of those Terms is unenforceable, void or voidable)
        (such claims, disputes and controversies, collectively, "Disputes") and not resolved after 60 days
        of negotiations since the rise of such dispute, will be eventually settled with the aid of using
        binding arbitration. The arbitrator shall have distinctive authority to clear up all Disputes, and the
        arbitral award will be last and binding upon the parties. The Arbitration shall take place in
        English. The seat of Arbitration shall be UAE. The venue of the arbitration proceedings can be
        online or determined at the convenience of the parties. All Users at this moment agree that any
        arbitration or claim shall be undertaken in their capacity and not as a joint class action.
        CARONFT.COM Company or any User may also provoke an arbitration intending through
        turning in written notice to the other, after that the parties shall reasonably cooperate to pick out
        an arbitrator and submit the Dispute to such arbitrator. In the event the parties are not able to
        agree on the choice of an arbitrator within 30 days from the submitting of a call for arbitration;
        The arbitrator's award will be written and reasoned, and binding on the parties in any
        jurisdictions. <br />
        The parties agree that the arbitral process shall be conducted confidentially, and disclosure of
        any information about the arbitrator proceedings or information learned during the
        proceedings could be detrimental to the business of the other party. The receiving party shall be
        liable to further damages if there is any breach of such Confidentiality and unauthorised
        disclosure of confidential information.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Indemnification</h3>
        You, at this moment, irrevocably release, acquit, and forever discharge CARONFT.COM and its
        subsidiaries, affiliates, officers, and successors of any liability for direct or indirect copyright or
        trademark infringement in respect of CARONFT.COM's use of a Works by this Agreement,
        including without limitation, CARONFT.COM's solicitation, encouragement, or request for You or
        third parties to host the Works to operate a distributed database and promote the Platform.
        You agree to indemnify and hold CARONFT.COM, its parents, subsidiaries, affiliates, officers and
        employees harmless from any claim or demand (including, without limitation, from all damages,
        liabilities, settlements, costs and attorney's fees) made by any third party due to or arising out of
        Your access to the Platform, use of the Platform, the violation of this Agreement by You, or the
        infringement by You, or any third party using Your account.
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Data and Privacy</h3>
        For information about how we handle personal information, please refer to our Privacy Policy at
         <a style={{color:"#cca016"}} href="https://www.CARONFT.COM/privacy-policy">https://www.CARONFT.COM/privacy-policy</a>. Using our Platform, you agree to be bound by our
        Privacy Policy.
        <div>Severability: If any provision of this Agreement is found invalid or unenforceable, it will be
        considered separate from this Agreement and will not affect the validity of any other provisions.</div>
        <div>Governing Law and Jurisdiction: These Terms will be controlled by and interpreted under the
        laws of the UAE. Although our services may be available in other jurisdictions, availability in such
        jurisdictions will not give rise to general or specific jurisdiction over CARONFT.COM Company
        in any forum outside the UAE.</div>
        <div>If you have a legal dispute with CARONFT.COM Company, please contact us first by emailing
        <a style={{color:"#cca016"}} href="mailto:support@CARONFT.COM">support@CARONFT.COM</a>, describing the nature of the dispute and providing all relevant
        documentation and evidence. We will attempt to settle any Dispute through negotiations in
        good faith.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Arbitration</h3>
        All disputes related to or arising from the present terms, or in reference to those Terms, or
        directly or indirectly concerning those Terms or any of the transactions considered under those
        Terms (including any claim that seeks to invalidate, or alleges that, any or all part of those Terms
        is unenforceable, void or voidable) (collectively referred to as "Disputes") and which are not
        resolved through negotiations within 60 days of the dispute's occurrence will be settled through
        binding arbitration. The arbitrator shall have exclusive authority to resolve all Disputes, and the
        arbitral award will be final and binding upon both parties. The arbitration proceedings shall be
        conducted in English, and the seat of Arbitration shall be in UAE.
        <br />
        Either CARONFT.COM company or any user may initiate an arbitration process by delivering
        written notice to the other party. The arbitrator's award will be written, reasoned, and binding on
        both parties in all jurisdictions.
        <div>The parties agree that the arbitral process shall be conducted confidentially. Disclosure of any
        information related to the arbitration proceedings or any information learned during the
        proceedings could be detrimental to the business of the other party. The receiving party shall be
        liable for further damages if there is any breach of confidentiality or unauthorised disclosure of
        confidential information.</div>
        <div>Contact <br />
        Meta Oryx Online Gaming Services LLC. <br />
        <a style={{color:"#cca016"}} href="mailto:support@CARONFT.COM">support@CARONFT.COM</a>.
        </div>
        </div>
        </div>
      </section>
    </div>
    <div className =" w-full relative bg-gray-200 overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border tracking-[normal] text-right text-17xl text-cold-grey-white font-montserrat" 
    style={{zoom: "0.8"}}>
       <Footer />
   </div>
   </>
  );
};

export default TC;