import React from "react";
import NFTcards from "./NFTcards";

const ParentNft = () => {
  return (
    <div>
      <div className="nft-container md:py-[80px] py-[40px] flex items-center justify-center w-[100%] text-white">
        <div className="max-w-[1340px] w-full mx-auto p-4 " data-aos="fade-up"  data-aos-duration="1500">
        <h2 className="text-white text-center md:text-[44px] text-[30px] mont italic m-0 md:mb-[40px] mb-[30px]">NFT Cards</h2>
          <NFTcards />
        </div>
      </div>
    </div>
  );
};

export default ParentNft;
