import React from 'react'
import Hero from '../components/Hero'
import Header from '../components/Header'
import AboutRefer from '../components/AboutRefer'
import NFTcards from '../components/NFTcards'
import ParentNft from '../components/ParentNft'
import PoweredBy from '../components/PoweredBy'
import HowToPlay from '../components/HowToPlay'
import WatchUs from '../components/WatchUs'
import GroupeLeague from '../components/GroupeLeague'
import ReferEarn from '../components/ReferEarn'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div>
      <section>
        <Hero/>
        <AboutRefer/>
        <ParentNft/>
        <GroupeLeague/>
        <PoweredBy/>
        <HowToPlay/>
        <WatchUs/>
        <ReferEarn/>
        <Footer/>
      </section>
    </div>
  )
}

export default Home
