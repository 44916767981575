import React from 'react'

function PoweredBy() {
  return (
    <div className='flex flex-row items-center text-center bg-[url("/public/binancesmartchain.png")] bg-center bg-no-repeat h-full md:h-[379px] w-full'>
        <div className='w-full justify-center items-center gap-[30px] md:gap-[95px] flex flex-row'>
            <div data-aos="fade-right"  data-aos-duration="1500" className='text-center mont text-white italic font-[700] leading-[30.04px] font-Montserrat text-[30.04px] md:leading-[66px] md:text-[44px]'>
                Powered by
            </div>
            <img  data-aos="fade-leftHow To Play" data-aos-duration="1500" src="/frame-1686560935.svg" alt="" className='w-[40%] h-[150px] md:w-[379px] md:h-[379px]'/>
        </div>
    </div>
  )
}

export default PoweredBy