import React from 'react'
import "./MatchCard.css"
function MatchCard() {
  return (
    <div className='relative w-full px-[20px] py-[20px] '  >
    <div className='lines'/>
        <div className='flex flex-col w-full gap-10 sm:gap-[10px] xs:gap-[10px]'>
        <div className="flex flex-row items-center justify-between gap-[10px]">
            <div className='font-[600] leading-[20.04px] card-font font-nunito-sans  md:leading-[26.04px]  lg:leading-[26.04px] xl:leading-[36.27px]'>
            India vs Australia T20 World Cup
            </div>
            <div className='font-[700] leading-[20.04px] text-d9d9d9 font-nunito-sans card-font md:leading-[26.04px]  lg:leading-[26.04px]  xl:leading-[36.27px]'>
            11 Players
            </div>
        </div>
        <div className="flex flex-row items-center justify-between gap-[20px] md:gap-[60px]">
            <div className='flex flex-col gap-[12px] md:gap-[20px] justify-between text-center h-full'>
                <img src="/ellipse-995@2x.png" alt="" className='image-size w-[50px] h-[50] object-contain'/>
                <div className='font-[400] leading-[20.04px] font-nunito-sans md:leading-[28.04px]  lg:leading-[30.04px] xl:leading-[36.27px]  card-font'>
                India
                </div>
            </div>
            <div className='flex flex-col justify-between gap-[12px] md:gap-[50px] '>
                <div className='flex flex-col gap-[18px]'>
                    <div className='font-[700] text-center mt-[22px] leading-[22.04px] font-nunito-sans text-[22.04px] md:leading-[30.04px] md:text-[30.04px] lg:leading-[32.04px] lg:text-[32.04px] xl:leading-[45.27px] xl:text-[45.27px]'>
                    VS
                    </div>
                    <div className='font-[700] leading-[22.04px] font-nunito-sans text-d9d9d9 text-[18px] md:leading-[20.04px] md:text-[20px]'>
                    Final · T20 55 of 55
                    </div>
                </div>
                <div>
                    <span className='font-[400] leading-[20.04px] font-nunito-sans md:leading-[28.04px] lg:leading-[30.04px] xl:leading-[36.27px] card-font'>
                    29th of june 
                    </span>
                    <span className='font-[400] leading-[20.04px] text-d9d9d9 font-nunito-sans card-font md:leading-[28.04px]  lg:leading-[30.04px]  xl:leading-[36.27px] '>
                    {` 08.00 pm`}
                    </span>    
                </div>
            </div>
            <div className='flex flex-col gap-[12px]  md:gap-[20px] text-center items-center justify-between'>
                <img src="southafrica.png" alt="" className='image-size'/>
                <div className='font-[400] leading-[20.04px] font-nunito-sans md:leading-[28.04px]  lg:leading-[30.04px]  xl:leading-[36.27px] card-font'>
                South Africa
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default MatchCard