import React from 'react'

function VideoCard(prop) {
  return (
    <div className='watch-sec'>
        <img src={prop.image} alt="" className='h-[80%] w-[100%] lg:h-[70%] lg:w-[100%] '/>
    </div>
  )
}

export default VideoCard