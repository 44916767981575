import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import images from "../playersCardDetails";
import { useState } from "react";
import toast from "react-hot-toast";
import "./CardDetailBg.css";

const CardDetailBg = () => {
  const { id } = useParams();
  const image = images[id - 1];
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const handleCopyClipboard = async (e) => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Link Copied to Clipboard");
    } catch (error) {
      console.error("Error copying to clipboard:", error);
      toast.error("Failed to copy link to clipboard. Please try again.");
    }
  };

  return (
    <section className="w-[1720px] flex flex-row items-end justify-start py-0 px-5 box-border gap-[75px] max-w-full shrink-0 text-left text-xl text-cold-grey-white font-montserrat mq450:gap-[0px_75px] mq800:gap-[75px] flex-wrap">
      {fullScreenImage && (
        <div
          className="fixed top-0 left-0 w-full h-full z-[9999] bg-black bg-opacity-80 flex items-center justify-center"
          onClick={() => setFullScreenImage(!fullScreenImage)}
        >
          <div className="absolute cursor-pointer top-0 text-[1.5rem] right-10 text-white">
            X
          </div>
          <img
            className="h-full w-full object-contain"
            src={image.card}
            alt=""
          />
        </div>
      )}
      <div className="w-[715px] flex flex-row items-start justify-start md:justify-center gap-[0px_17px] min-w-[715px] max-w-full mq800:flex-wrap mq800:min-w-full mq1350:flex-1">
        <img
          className="h-full flex-1 relative max-w-[845px] max-h-[836px] overflow-hidden min-w-[420px] z-[1] mq800:min-w-full w-[110px]"
          loading="eager"
          alt=""
          src={image.card}
        />
        <div className="flex flex-row md:flex-col items-start justify-start gap-[14px] md:gap-[14px_0px]">
          <img
            onClick={handleCopyClipboard}
            className="w-[42px] h-[42px] mt-[10px] cursor-pointer relative rounded-26xl overflow-hidden shrink-0 z-[1]"
            loading="eager"
            alt=""
            src="/frame-1686560899.svg"
          />
          <img
            onClick={() => setFullScreenImage(!fullScreenImage)}
            className="w-[42px] h-[42px] mt-[10px] cursor-pointer relative rounded-26xl overflow-hidden shrink-0 z-[1]"
            loading="eager"
            alt=""
            src="/frame-1686560900.svg"
          />
          {/* <div className="w-[52px] h-[52px] [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.4)_0.55%,_rgba(255,_255,_255,_0.04))] hover:bg-red-800 flex justify-center items-center cursor-pointer relative rounded-26xl overflow-hidden shrink-0 object-cover z-[1]">
            <img
              className="w-[30.02px] h-[26.69px] cursor-pointer relative overflow-hidden shrink-0 object-cover z-[1]"
              loading="eager"
              alt=""
              src="/frame-1686560901@2xx.png"
            />
          </div> */}
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start justify-start gap-[32px_0px] min-w-[578px] max-w-full mq450:gap-[32px_0px] mq800:min-w-full pr-[77px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px_0px] max-w-full mq450:gap-[32px_0px]">
          <div className="relative leading-[120%] inline-block max-w-full z-[1] mq450:text-base mq450:leading-[19px]">
            CCL Bronze Players Caro NFT Exclusive NFTs
          </div>
          <div className="self-stretch flex flex-row items-end justify-between gap-[20px] max-w-full text-base font-nunito-sans mq800:flex-wrap">
            <div className="w-[371px] flex flex-col items-start justify-start gap-[3px_0px] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-between gap-[20px] z-[1] mq450:flex-wrap">
                <div className="relative leading-[100%] font-semibold">
                  {image.location}
                </div>
                <div className="relative text-mini leading-[100%] font-semibold">
                  {image.match}
                </div>
              </div>
              <h2 className="m-0 relative text-11xl leading-[100%] font-bold font-montserrat text-d9d9d9 z-[1] mq450:text-lg mq450:leading-[18px] mq800:text-5xl mq800:leading-[24px]">
                {image.postion}
              </h2>
            </div>
            <button className="cursor-pointer [border:none] py-1.5 pr-[37px] pl-[34px] bg-[transparent] flex flex-row items-center justify-center relative z-[1] mr-[32px]">
              <img
                className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-8xs max-w-full overflow-hidden max-h-full"
                alt=""
                src="/rectangle-7686.svg"
              />
              <div className="relative text-xl leading-[90%] font-semibold font-nunito-sans text-gray-400 text-left z-[1] mq450:text-base mq450:leading-[14px]">
                {image.rarity}
              </div>
            </button>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border gap-[20px_0px] max-w-full text-5xl font-nunito-sans">
          <div className="self-stretch relative leading-[120%] z-[1] mq450:text-lgi mq450:leading-[23px]">{`This is an exclusive ${image.rarity}-level ${image.postion} NFT card that can attached to the ${image.postion}. It will give extra points to your team, which will help you lead the leaderboard.`}</div>
          <div className="w-[346px] flex flex-row items-start justify-between py-0 pr-px pl-0 box-border gap-[20px] max-w-full text-xl font-montserrat mq450:flex-wrap">
            <div className="flex flex-row items-center justify-start gap-[0px_6px] text-9xl">
              <div className="h-8 w-8 relative rounded-26xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.4)_0.55%,_rgba(255,_255,_255,_0.04))] z-[1]">
                <img
                  className="absolute top-[6px] left-[6px] w-5 h-5 object-cover"
                  loading="eager"
                  alt=""
                  src="/image-65@2x.png"
                />
              </div>
              <div className="relative leading-[10px] font-semibold z-[1] mq450:text-3xl mq450:leading-[8px]">
                500
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[0px_5px]">
              <div className="rounded-26xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.4)_0.55%,_rgba(255,_255,_255,_0.04))] flex flex-row items-center justify-center py-2.5 pr-[9px] pl-2.5 z-[1]">
                <b className="relative leading-[12px] mq450:text-base mq450:leading-[10px]">
                  $
                </b>
              </div>
              <div className="relative text-9xl leading-[10px] font-semibold z-[1] mq450:text-3xl mq450:leading-[8px]">
                1
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[0px_6px] font-nunito-sans">
              <div className="rounded-26xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.4)_0.55%,_rgba(255,_255,_255,_0.04))] flex flex-row items-center justify-center py-[11px] px-2.5 z-[1]">
                <b className="relative leading-[10px] mq450:text-base mq450:leading-[8px]">
                  ₹
                </b>
              </div>
              <div className="relative text-9xl leading-[10px] font-semibold font-montserrat z-[1] mq450:text-3xl mq450:leading-[8px]">
                87.72
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[3px] box-border max-w-full text-7xl">
          <div className="flex-1 rounded-11xl box-border flex flex-col items-center justify-start pt-[30px] px-[30px] pb-8 gap-[34px_0px] max-w-full z-[1] border-[1px] border-solid border-dimgray-200 mq450:gap-[34px_0px]">
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-[9px]">
              <h3 className="m-0 relative text-inherit font-bold font-inherit mq450:text-2xl">
                Player Potential
              </h3>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-[9px] gap-8 box-border  max-w-full text-xl text-d9d9d9 font-nunito-sans mq450:gap-8 mq800:flex-wrap">
              {/* <div className="flex-1 flex flex-col items-start justify-start gap-[34px_0px] max-w-full mq450:gap-[34px_0px] mq800:flex-1 mq800:min-w-full">
                <div className="self-stretch flex flex-row items-start justify-start mq925:gap-2 gap-2 mq450:gap-2 mq800:flex-wrap">
                  <div className="relative whitespace-nowrap leading-[100%] mq450:text-base mq450:leading-[16px]">
                    <b>Skill:</b>
                    <span className="text-cold-grey-white whitespace-nowrap">
                      {" "}
                      20 (out of 99)
                    </span>
                  </div>
                  <div className="relative whitespace-nowrap leading-[100%] mq450:text-base mq450:leading-[16px]">
                    <b>Reward Points:</b>
                    <span className="text-cold-grey-white"> 500</span>
                  </div>
                </div>
                <div className="w-full flex flex-col items-start justify-start gap-[14px_0px] max-w-full text-lg text-cold-grey-white font-montserrat">
                  <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                    <div className="relative font-medium">Energy</div>
                    <div className="relative font-medium">36</div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[10px_0px]">
                    <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-px relative">
                    <div className="w-full bg-gray-200 rounded-full h-2.5 bg-gray-500">
                          <div className="bg-d9d9d9 h-2.5 rounded-full" 
                          style={{width: "36%"}}></div>
                        </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                        <div className="relative font-medium">Life</div>
                        <div className="relative font-medium">36</div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-px relative">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 bg-gray-500">
                          <div className="bg-d9d9d9 h-2.5 rounded-full" 
                          style={{width: "36%"}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="flex-1 flex flex-col items-start justify-start gap-[34px_0px]  max-w-full mq450:gap-[34px_0px] mq800:flex-1 mq800:min-w-full">
                <div className="flex flex-1 flex-col gap-[6px]">
                  <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px] whitespace-nowrap">
                    <b>Skill:</b>
                    <span className="text-cold-grey-white">
                      {" "}
                      20 (out of 99)
                    </span>
                  </div>
                  <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px] whitespace-nowrap">
                    <b>Reward points:</b>
                    <span className="text-cold-grey-white">500</span>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-1 box-border max-w-full text-lg text-cold-grey-white font-montserrat">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[18px_0px] max-w-full">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                        <div className="relative font-medium">Energy</div>
                        <div className="relative font-medium">36</div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-px relative">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 bg-gray-500">
                          <div
                            className="bg-d9d9d9 h-2.5 rounded-full"
                            style={{ width: "36%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                        <div className="relative font-medium">Life</div>
                        <div className="relative font-medium">36</div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-px relative">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 bg-gray-500">
                          <div
                            className="bg-d9d9d9 h-2.5 rounded-full"
                            style={{ width: "36%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[34px_0px]  max-w-full mq450:gap-[34px_0px] mq800:flex-1 mq800:min-w-full mt-[25px]">
                <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px] whitespace-nowrap">
                  <b>Validity:</b>
                  <span className="text-cold-grey-white"> 3 Month</span>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-1 box-border max-w-full text-lg text-cold-grey-white font-montserrat">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[18px_0px] max-w-full">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                        <div className="relative font-medium">Level</div>
                        <div className="relative font-medium">36</div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-px relative">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 bg-gray-500">
                          <div
                            className="bg-d9d9d9 h-2.5 rounded-full"
                            style={{ width: "36%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                        <div className="relative font-medium">Repair</div>
                        <div className="relative font-medium">36</div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-px relative">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 bg-gray-500">
                          <div
                            className="bg-d9d9d9 h-2.5 rounded-full"
                            style={{ width: "36%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[3px] box-border max-w-full text-11xl">
          <div className="flex-1 rounded-11xl box-border flex flex-col items-start justify-start py-[30px] px-10 max-w-full z-[1] border-[1px] border-solid border-dimgray-200 mq800:gap-[34px_0px]">
            <div className="flex flex-row items-center justify-start gap-[0px_34px] max-w-full mq450:gap-[0px_34px] mq800:flex-wrap">
              <img
                className="h-20 w-20 relative rounded-41xl overflow-hidden shrink-0 object-cover"
                loading="eager"
                alt=""
                src="/user-1jpg@2x.png"
              />
              <div className="flex flex-col items-start justify-start">
                <h2 className="m-0 relative text-inherit font-semibold font-inherit mq450:text-lg mq800:text-5xl">
                  {image.owner}
                </h2>
                <div className="relative text-lg">Owner</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[738px] flex flex-row items-start justify-start gap-[0px_24px] max-w-full mq800:flex-wrap">
          <Button
            className="h-[54px] flex-[0.4771] max-w-full z-[1] mq450:pl-5 mq450:pr-5 mq450:box-border mq450:flex-1"
            disableElevation={true}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "16",
              background:
                "linear-gradient(92.23deg, #cca017, #38427d 58%, #171b34)",
              borderRadius: "100px",
              "&:hover": {
                background:
                  "linear-gradient(92.23deg, #cca017, #38427d 58%, #171b34)",
              },
              height: 54,
            }}
          >
            Rent for 6 months
          </Button>
          <Button
            className="h-[54px] flex-1  max-w-full z-[1]"
            disableElevation={true}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "16",
              background:
                "linear-gradient(92.23deg, #cca017, #38427d 58%, #171b34)",
              borderRadius: "100px",
              "&:hover": {
                background:
                  "linear-gradient(92.23deg, #cca017, #38427d 58%, #171b34)",
              },
              height: 54,
            }}
          >
            Buy Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CardDetailBg;
