import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loader from '../../config/Loader';


const Profile = () => {
  let navigate = useNavigate()
  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [dob, setDob] = useState('');
  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const registerResponse = JSON.parse(localStorage.getItem('registerResponse'));
    if (registerResponse) {
      setAuthKey(registerResponse.auth_key);
      setUserId(registerResponse.userid);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      teamName,
      inviteCode,
      dob,
      countryId,
      stateId,
      userId, 
    };
    setLoading(true);
    try {
      const response = await axios.post('https://caro11.com/caro11_admin/api/editprofile', formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authKey}`,
        },
      });
  
      console.log('Success:', response.data);
  
      if (Array.isArray(response.data) && response.data.length > 0) {
        const firstItem = response.data[0];
        console.log("first",firstItem)
        if (firstItem.success) {
          toast.success(firstItem.message, { position: 'top-right', duration: 5000 });
          navigate('/download'); 
        } 
        else {
          response.data.forEach(item => {
            toast.error(item.message, { position: 'top-right', duration: 5000 });
          });
        }
      } else {
        toast.error('An error occurred while submitting the form.', { position: 'top-right', duration: 5000 });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
  
      if (error.response && Array.isArray(error.response.data)) {
        error.response.data.forEach(item => {
          toast.error(item.message, { position: 'top-right', duration: 5000 });
        });
      } else {
       
      }
    }finally {
      setLoading(false);
    }

  
  };

  return (
    <section id="banner" className="bg-gray-900 banner">
        {loading && (<Loader/>)}
      <div className="container mx-auto sm:py-8 py-2 px-4">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 text-center md:text-left">
            <div className="flex items-center justify-start md:justify-start mt-4 md:mt-0 space-x-4 pos-ab">
              <img className="w-16" src="/img/carlo-logo.svg" alt="Logo" />
              <h2 className="sub-heading font-yellow">CaroNFT</h2>
            </div>
            <div className="mt-4 w-full md:w-3/4">
              <img
                className="mx-auto bonus-img"
                src="/img/Bonus-img.png"
                alt="bonus"
                height="294"
                width="381"
              />
              <h6 className="sub-heading-small text-white text-center mt-4 mb-0 ">Just a Step Away to get exciting rewards worth up to</h6>
              <h3 className="sub-heading-bold font-yellow text-center sm:mt-3 mt-3 mb-0">$2000 NFTs</h3>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <Link to="/" className="hover-none">
              <h1 className="main-heading text-white flex items-center">
                <img className="mr-3" src="/img/arrow-right.svg" alt="" /> Create Profile
              </h1>
            </Link>
            <form className="mt-5" onSubmit={handleSubmit}>
      <fieldset>
        <div className="mb-3">
          <label className="text-white form-heading" htmlFor="teamName">
            Enter Team Name*
          </label>
          <div className="flex items-center mt-2">
            <input
              id="teamName"
              type="text"
              placeholder="Enter Team Name*"
              className="form-control w-full border-b border-gray-400 bg-transparent text-white py-2 px-3 focus:outline-none focus:border-yellow-500"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            <div className="input-group-text bg-transparent border-none bo-bottom">
              <img src="/img/file-icon.svg" alt="File Icon" />
            </div>
          </div>
        </div>

        {/* <div className="mb-3">
          <label className="text-white form-heading" htmlFor="inviteCode">
            Invite Code
          </label>
          <div className="flex items-center mt-2">
            <input
              id="inviteCode"
              type="tel"
              placeholder="Invite Code"
              className="form-control w-full border-b border-gray-400 bg-transparent text-white py-2 px-3 focus:outline-none focus:border-yellow-500"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
            />
            <div className="input-group-text bg-transparent border-none bo-bottom">
              <img src="/img/phone.svg" alt="Phone Icon" />
            </div>
          </div>
        </div> */}

        <div className="mb-3">
          <label className="text-white form-heading" htmlFor="dob">
            Date of Birth*
          </label>
          <div className="flex items-center mt-2">
            <input
              id="dob"
              type="date"
              className="form-control w-full border-b border-gray-400 bg-transparent text-white py-2 px-3 focus:outline-none focus:border-yellow-500"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
        </div>

        {/* <div className="mb-3">
          <label className="text-white form-heading mb-3" htmlFor="country">
            Select Your Country*
          </label>
          <CountrySelect
            onChange={(e) => setCountryId(e.id)}
            placeHolder="Select Country"
          />
        </div> */}

        {/* <div className="mb-3">
          <label className="text-white form-heading mb-3" htmlFor="state">
            Select Your State*
          </label>
          <StateSelect
            countryId={countryId}
            onChange={(e) => setStateId(e.id)}
            placeHolder="Select State"
          />
        </div> */}

        <div className="inner-box mt-4">
          <p className="text-white p-small">
            To Play the <span className="font-yellow"><b>CaroNFT</b></span> Contests(s), You Must be 18 Years or Above.
          </p>
          <p className="text-white p-small">
            You Are Not a Resident of Andhra Pradesh, Assam, Nagaland, Odisha, Telangana.
          </p>
          <p className="text-white p-small">
            This Fantasy Game Involves an Element of Financial Risk & You May Get Addicted. Please Play at Your Own Risk & Responsibility.
          </p>
        </div>

        <button className="btn-style sm:mt-[60px] mt-[40px]  sm:mb-[0px] mb-[40px]  w-full py-2 rounded-lg bg-white text-black font-bold ">
          Create Profile
        </button>
      </fieldset>
    </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
