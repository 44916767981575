import React, { useEffect, useState } from 'react'
import { Button } from "@mui/material";
import MobileNav from './MobileNav';
import { Link } from 'react-router-dom';
import hamburgerIcon from "../icons/Vector.png";
import apkFile from "../assests/app-release.apk"

const Header = (props) => {
    const handleDownload = () => {
      const apkUrl = apkFile
      const link = document.createElement('a');
      link.href = apkUrl;
      link.download = 'caro11-app.apk';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
      const [openMobileMenu, setOpenMobileMenu] = useState(false);
      const [screenWidth, setScreenWidth] = useState(window.innerWidth);
      const activePage = props?.activePage || "";
          useEffect(() => {
          addEventListener("resize", () => {
          setScreenWidth(window.innerWidth);
  
          return () => removeEventListener("resize", () => {
              setScreenWidth(window.innerWidth);
          });
          }); }, [screenWidth]);
      
      const classes = "relative tracking-[1px] leading-[21px] uppercase font-medium whitespace-nowrap no-underline text-white"
      const classActive = "relative tracking-[1px] leading-[21px] uppercase font-medium whitespace-nowrap no-underline text-d9d9d9"


  return (
    <>
    {openMobileMenu && <MobileNav setOpenMobileMenu={setOpenMobileMenu} />}
    <div className="relative z-50 ">
      <div className="max-w-[1200px] w-full mx-auto p-4 grid grid-cols-12 gap-4 ">
        <div className="image-container text-white col-span-3">
          <div className="img-box">
          <img className="logo-shadow" src="/img/caro-logo.svg" alt="logo" />
          </div>
        </div>

        <div className="image-container text-end flex items-center justify-end col-span-9">





        <div className="flex flex-row items-center justify-end gap-[0px_69px] max-w-full mq450:gap-[0px_69px] mq925:gap-[0px_69px] ">
                  {screenWidth > 1100? <><div> <Link to={'/'} className={activePage === '' ? classActive : classes}>
                    Home
                    </Link>
                  </div>
                  <Link to={'/about-us'} className={activePage === 'about-us' ? classActive : classes}>
                    About Us
                  </Link>
                  <Link to={'/how-to-play'} className={activePage === 'how-to-play' ? classActive : classes}>
                    How To Play
                  </Link>
                  <Link to={'/refer-&-earn'} className={activePage === 'refer-&-earn' ? classActive : classes}>
                    refer & earn
                  </Link>
                  <a href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link  " target="_blank">
                  <Button className="download-button rounded-81xl [background:linear-gradient(92.23deg,_#cca017,_#38427d_58%,_#171b34)] flex flex-row items-center justify-center pt-4 pb-[15px] pr-[26px] pl-[29px] whitespace-nowrap text-center">
                    <div className="relative tracking-[0.6px] leading-[22.5px] no-underline text-[#ffffff]">
                      Download App
                    </div>
                  </Button>
                  </a>
                  </> : <>
                  <a href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link " target="_blank">
                  <Button  className=" download-button rounded-81xl [background:linear-gradient(92.23deg,_#cca017,_#38427d_58%,_#171b34)] flex flex-row items-center justify-center pt-4 pb-[15px] pr-[26px] pl-[29px] whitespace-nowrap text-center">
                    <div className="relative tracking-[0.6px] leading-[22.5px] no-underline text-[#ffffff]">
                      Download App
                    </div>
                  </Button>
                  </a>
                  <div className="relative ">
                    <button  style={{backgroundColor: 'transparent'}} onClick={() => setOpenMobileMenu(true)}>
                        <img className=" w-6 h-6" src={hamburgerIcon} />
                    </button>
                  </div>
                  </>
                    }
                </div>

                 
                  
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
