import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import apkFile from "../assests/app-release.apk"


const MobileNav = ({ setOpenMobileMenu }) => {

    const handleDownload = () => {
        const apkUrl = apkFile
        const link = document.createElement('a');
        link.href = apkUrl;
        link.download = 'caro11-app.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    return (
        <div style={{zIndex: 999}} className="w-full right-0 left-auto fixed h-screen top-0 bg-[url('/public/iphone_hero_bg.png')] bg-cover bg-no-repeat p-12 flex flex-col gap-10">
            <div className="flex justify-between items-center">
                <Link to="/">
                <img onClick={() => setOpenMobileMenu(false)} src={"/header--link--imagelogopng@2x.png"} alt="logo" className="md:w-[13.5rem] w-40" />
                </Link>
                <button className="bg-transparent" onClick={() => setOpenMobileMenu(false)}>
                    <img className="w-12 h-12 " src={"/cross.svg"} />
                </button>
            </div>
            <div className="flex flex-col gap-20 items-center mt-10">
                <div className="flex flex-col items-center  text-[#050038] text-lg gap-8">    
                    <Link to='/' className='h-full py-0 text-[16px] font-[600] font-Montserrat leading-[24px]' >
                        <button className=" bg-transparent tracking-[0.6px] text-white leading-[22.5px] text-3xl" onClick={() => setOpenMobileMenu(false)}>Home</button>
                    </Link>
                    <Link to='/about-us' className='h-full py-0 text-[16px] font-[600] font-Montserrat leading-[24px] ' >
                        <button className=" bg-transparent tracking-[0.6px] text-white leading-[22.5px] text-3xl" onClick={() => setOpenMobileMenu(false)}>About Us</button>
                    </Link>
                    <Link to='/how-to-play' className='h-full py-0 text-[16px] font-[600] font-Montserrat leading-[24px] ' >
                        <button className=" bg-transparent tracking-[0.6px] text-white leading-[22.5px] text-3xl" onClick={() => setOpenMobileMenu(false)}>How to play</button>
                    </Link>
                    <Link to='/refer-&-earn' className='h-full py-0 text-[16px] font-[600] font-Montserrat leading-[24px] ' >
                        <button className=" bg-transparent tracking-[0.6px] text-white leading-[22.5px] text-3xl" onClick={() => setOpenMobileMenu(false)}>Refer & Earn</button>
                    </Link>
                </div>
                <a href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link " target="_blank">
                <Button  className="rounded-81xl [background:linear-gradient(92.23deg,_#cca017,_#38427d_58%,_#171b34)] flex flex-row items-center justify-center pt-4 pb-[15px] pr-[26px] pl-[29px] whitespace-nowrap text-center">
                    <div className="relative tracking-[0.6px] leading-[22.5px] no-underline text-[#ffffff]">
                      Download App
                    </div>
                </Button>
                </a>
            </div>
        </div>
    );
}

export default MobileNav;
