import React, { useEffect, useState } from 'react';
import axiosInstance from "./axiosInstance";
import './Register.css';
import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loader from '../../config/Loader';


const Register = () => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false);


 const { inviteCode } = useParams();
 

  const [formData, setFormData] = useState({
    inviteCode: '',
    mobileNumber: '',
    email: '',
    password: '',
    isAbove18: false,
  });

  const [errors, setErrors] = useState({
    inviteCode: '',
    mobileNumber: '',
    email: '',
    password: '',
    isAbove18: '',
  });

  const [showOTP, setShowOTP] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (inviteCode) {
      setFormData(prevState => ({
        ...prevState,
        inviteCode: inviteCode,
      }));
    }
  }, [inviteCode]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
    return regex.test(password);
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {
      inviteCode: '',
      mobileNumber: '',
      email: '',
      password: '',
      isAbove18: '',
    };

    if (!formData.inviteCode) {
      newErrors.inviteCode = 'Invite code is required';
      valid = false;
    }
    if (!formData.mobileNumber) {
      newErrors.mobileNumber = 'Mobile number is required';
      valid = false;
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
      valid = false;
    } else if (!validatePassword(formData.password)) {
      newErrors.password = 'Invaild Format';
      valid = false;
    }
    if (!formData.isAbove18) {
      newErrors.isAbove18 = 'You must be above 18';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const apiData = {
        email: formData.email,
        password: formData.password,
        mobile: formData.mobileNumber,
        country: '91',
        refercode: formData.inviteCode || '',
      };
      setLoading(true);
      try {
        const response = await axiosInstance.post('/tempregisteruser', apiData);
        console.log('API Response:', response.data);
  
       
        if (Array.isArray(response.data) && response.data.length > 0) {
          const firstItem = response.data[0];
          if (firstItem.success) {
            toast.success(firstItem.message, {
              position: 'top-right',
              duration: 5000, 
            });
            localStorage.setItem('registerResponse', JSON.stringify(firstItem));
            localStorage.setItem('userEmail', formData.email);

            navigate('/otp')
          } else {
      
            response.data.forEach(item => {
              toast.error(item.message, {
                position: 'top-right',
                duration: 5000, 
              });
            });
          }
        } else {
          toast.error('An error occurred while submitting the form.', {
            position: 'top-right',
            duration: 5000,
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
  
        if (error.response && Array.isArray(error.response.data)) {

          error.response.data.forEach(item => {
            toast.error(item.message, {
              position: 'top-right',
              duration: 5000, 
            });
          });
        } else {
        
        }
      }finally {
        setLoading(false);
      }

    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    
    <section id="banner" className="bg-gray-900 banner">
      {loading && (<Loader/>)}
      <div className="container mx-auto sm:py-8 py-2 px-4">
     
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-1/2 text-center md:text-left">
              <div className="flex items-center justify-start md:justify-start mt-4 md:mt-0 space-x-4 pos-ab">
                <img className="w-16" src="/img/carlo-logo.svg" alt="Logo" />
                <h2 className="sub-heading font-yellow">CaroNFT</h2>
               
              </div>
              <div className="mt-4 w-full md:w-3/4">
                <img className="mx-auto bonus-img" src="/img/Bonus-img.png" alt="bonus" height="294" width="381" />
                <h6 className="sub-heading-small text-white text-center mt-4 mb-0 ">Just a Step Away to get exciting rewards worth up to</h6>
                <h3 className="sub-heading-bold font-yellow text-center sm:mt-3 mt-3 mb-0">$2000 NFTs</h3>
              </div>
            </div>
            <div className="w-full md:w-1/2 mt-8 md:mt-0">
              <h1 className="main-heading text-white ">Register & Play</h1>
              <form className="mt-8" onSubmit={handleSubmit}>
                <fieldset>
                  <div className="mb-4">
                    <label className="form-heading text-white block mb-2">Enter Invite Code</label>
                    <div className="relative">
                      <input
                        type="text"
                        name="inviteCode"
                        placeholder="Enter Invite Code"
                        value={formData.inviteCode}
                        onChange={handleChange}
                        className="form-control w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <span className="input-group-text absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none inp-img">
                        <img src="/img/file-icon.svg" alt="Logo" />
                      </span>
                    </div>
                    {errors.inviteCode && <span className='fo-small text-white'>{errors.inviteCode}</span>}
                  </div>

                  <div className="mb-4">
                    <label className="form-heading text-white block mb-2">Enter Mobile Number</label>
                    <div className="relative">
                      <input
                        type="tel"
                        name="mobileNumber"
                        placeholder="Enter Mobile Number"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        className="form-control w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />

                      <span className="input-group-text absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none inp-img">
                        <img src="/img/phone.svg" alt="Logo" />
                      </span>
                      
                    </div>
                    {errors.mobileNumber && <span className='fo-small text-white'>{errors.mobileNumber}</span>}
                    <p className="p-small text-white mt-1">You Will Receive an OTP for Verification</p>
                  </div>

                  <div className="mb-4">
                    <label className="form-heading text-white block mb-2">Email Address</label>
                    <div className="relative">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <span className="input-group-text absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none inp-img">
                        <img src="/img/email.svg" alt="Logo" />
                      </span>
                      
                    </div>
                    {errors.email && <span className='fo-small text-white'>{errors.email}</span>}
                  </div>

                  <div className="mb-4">
                    <label className="form-heading text-white block mb-2">Password</label>
                    <div className="relative">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        placeholder="Enter Password"
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <span
                        className="input-group-text absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer inp-img"
                        onClick={togglePasswordVisibility}
                      >
                        <img
                          src={passwordVisible ? "/img/hide-pass.svg" : "/img/show-pass.svg"}
                          alt={passwordVisible ? "Hide password" : "Show password"}
                        />
                      </span>
                    </div>
                    {errors.password && <span className='fo-small text-white'>{errors.password}</span>}
                    <p className="p-small text-white mt-1">Must be 8 to 16 Characters with 1 Number, 1 Alphabet & 1 Symbol</p>
                  </div>

                  <div className="mb-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="isAbove18"
                        checked={formData.isAbove18}
                        onChange={handleChange}
                        className="form-checkbox text-yellow-500"
                      />
                      <span className="ml-2 text-white">I Certify That I am Above 18 Years.</span>
                    </label>
                  </div>

                  <button className="btn-style w-full py-2 rounded-lg" type="submit">
                    Register
                  </button>
                </fieldset>
              </form>
              <div className="box-w text-white text-center mt-8">
                {/* <h6 className="my-4">OR</h6>
                <div className="flex justify-center g-20">
                  <button className="btn-style-two btn">
                    <img src="/img/goole.svg" alt="google" /> Google Login
                  </button>
                  <button className="btn-style-two btn">
                    <img src="/img/facebook.svg" alt="facebook" /> Facebook Login
                  </button>
                </div> */}
                <p className="mt-4">
                Registering, I Agree to <span className="font-yellow"><b>CaroNFT</b></span> T&Cs
                </p>
              </div>
            </div>
          </div>
        
      </div>
    </section>
  );
};

export default Register;
