import React from "react";
import { Link } from "react-router-dom";
import apkFile from "../assests/app-release.apk"

const AboutRefer = () => {
  const handleDownload = () => {
    const apkUrl = apkFile
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'caro11-app.apk';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      <div className="bg-about-container  items-center justify-center w-[100%] text-white mdd2:py-[100px] py-[50px]">
        <div className="max-w-[1200px] w-full mx-auto" data-aos="fade-up"  data-aos-duration="1000">
          <div className=" md:px-[80px]  md:py-[60px] py-[20px] px-[40px] grid grid-cols-1 md:grid-cols-2 gap-0 gridient-bg">
            <div className="image-container mdd2:text-left text-center  flex flex-col justify-center">
              <h2 className="text-white md:text-[50px] md:leading-[60px] text-[30px] mont italic m-0">
                Refer and Earn
              </h2>
              <p className="md:text-[24px] md:leading-[30px] text-[16px] mont font-normal mt-2">
                Earn Big Rewards By inviting your friends
              </p>
            </div>
            <div className="image-container flex items-center mdd2:justify-end justify-center">
           <a href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link" target="_blank">
              <button className="download-button md:mt-[20px]">
                Download App
              </button>
              </a>
            </div>
          </div>
        </div>

        <div className="max-w-[1200px] w-full mx-auto p-4 grid grid-cols-1 md:grid-cols-2 gap-10 md:mt-[80px] mt-[50px]">
          <div className="image-container flex items-center justify-center" data-aos="fade-right"  data-aos-duration="2000">
            <img
              src="/img/about-one.png"
              alt="Right Image"
              className=" md-w-[575px] w-full h-auto object-contain"
            />
          </div>

          <div className="image-container text-left flex flex-col justify-center" data-aos="fade-left"  data-aos-duration="2000">
            <h2 className="text-white md:text-[44px] text-[30px] mont italic m-0">
              About us
            </h2>
            <p className="md:text-[16px] md:leading-[32px] text-[16px] mont off-white">
              The Caro NFT App is an NFT cricket collectible game that operates on
              blockchain technology. Our app offers exclusive and user-friendly
              features that help you improve your gaming skills and win
              real-time amazing rewards. Our platform prioritizes fair play and
              provides a safe and secure environment for users to enjoy. Using
              blockchain and NFTs, we have developed a global NFT cricket
              collectible gaming platform where everyone can play fearlessly. We
              aim to become a market leader in the cricket collectible NFT
              gaming industry.
            </p>
             <Link to={"/about-us"}> <button className="download-button md:mt-[20px]">
              Explore Market
            </button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutRefer;
