import React from 'react'
import Footer from "../components/Footer";
import Nav from "../components/Nav";

function ReferNEarnPage() {
      return (
        <>
        <div className="w-full relative bg-gray-200 overflow-hidden flex flex-col items-center justify-start pt-10 px-0 box-border gap-[173px_0px] tracking-[normal] mq1125:gap-[173px_0px] pb-5 mq800:gap-[173px_0px]">
          <img
            className="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/bg@2x.png"
          />
          <section className="w-[1720px] z-20 flex flex-col items-center justify-start py-0 px-5 box-border gap-[11px_0px] max-w-full shrink-0 text-justify text-xl text-cold-grey-white font-montserrat">
            <Nav activePage="refer-&-earn"/>
            <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[69px] box-border max-w-full text-left text-45xl">
              <h1 className="m-0 relative text-inherit uppercase font-normal font-inherit z-[1] mq450:text-19xl mq800:text-32xl">{`Refer & Earn`}</h1>
            </div>
            <div className=" w-full py-0 px-0 md:px-32">
            <div className=" flex flex-col items-start justify-start gap-[11px_0px]">
            <h3 className="my-3 text-primary fs-18 font-weight-bold">Refer And Earn</h3>
            <div>To be eligible for referral bonuses, you must have an account on CARONFT.COM and invite new
            users, referred to as “friends,". The individuals you invite must purchase the caro NFTs drop and
            cannot already have an account on CARONFT.COM.</div>
            <div>To qualify for the "Refer & Earn" bonus, the person(s) you invite must use the unique referral link/
            code provided in your invitation and sign up for CARONFT.COM using an email address that is
            not associated with any other CARONFT.COM. You and the referred person must complete a
            valid KYC verification to receive the referral Bonus.</div>
            <div>Your referral remains valid until the caro NFT drop is made live by CARONFT.COM. There are no
            limitations on the number of people you can invite. However, the person you invite must meet
            all the eligibility criteria and pass verification to become an active user.</div>
            <div>To claim a Referral Bonus, the Referred User must 1) Verify their Email Address and 2) Verify their
            identity by completing the KYC process. To claim a Referral Bonus, the Referring User must 1)
            Verify their Email Address and 2) Verify their identity by completing the KYC process.</div>
            <div>Creating multiple accounts on CARONFT.COM to receive various rewards is not allowed, and
            doing so will result in penalties, including cancellation of referral benefits and account
            suspension.</div>
            <div>To use CARONFT.COM's platform, you must be at least 18 or of minimum legal age in any
            jurisdiction (whichever is later). Any referred sign-ups and purchases that violate the laws of their
            jurisdiction will attract penalties, including cancellation of referral benefits and suspension of the
            referred accounts.</div>
            <div>CARONFT.COM reserves the right to withdraw this bonus offer at any time if you violate any
            conditions of the Terms and Conditions of <a style={{color:"#cca016"}} href="https://www.CARONFT.COM">https://www.CARONFT.COM</a>. The decision of the
            CARONFT.COM management shall be final and binding in case of any disputes. All standard
            CARONFT.COM terms and conditions apply.</div>

            </div>
            </div>
          </section>
        </div>
        <div className =" w-full relative bg-gray-200 overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border tracking-[normal] text-right text-17xl text-cold-grey-white font-montserrat" 
        style={{zoom: "0.8"}}>
           <Footer />
       </div>
       </>
      );
    };
        

export default ReferNEarnPage