import CardDetailBg from "../components/CardDetailBg";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const CardDetailPage = () => {
  return (
    <>
    <div className="w-full relative bg-gray-200 bg-cover overflow-hidden gap-[40px] flex flex-col pb-4">
      <div className="mt-5 md:mt-0"/>
      <Nav />
      <div className="flex flex-col items-center justify-start  px-0 pb-0 box-border gap-[160px_0px] tracking-[normal] mq800:gap-[160px_0px] mq1125:gap-[160px_0px]" >

      <img
        className="w-full h-full absolute my-0 mx-[!important] top-0 right-0 object-cover max-w-full overflow-hidden max-h-full "
        alt=""
        src="/bg@2x.png"
        />
      <CardDetailBg />
        </div>
    </div>
    <div className =" w-full relative bg-gray-200 overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border tracking-[normal] text-right text-17xl text-cold-grey-white font-montserrat" 
        style={{zoom: "0.8"}}>
          <Footer />
      </div>
        </>
  );
};

export default CardDetailPage;
