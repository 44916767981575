import scard1 from './assests/nftcards/scard1.png';
import scard2 from './assests/nftcards/scard2.png';
import scard3 from './assests/nftcards/scard3.png';
import scard4 from './assests/nftcards/scard4.png';
import scard5 from './assests/nftcards/scard5.png';
import scard6 from './assests/nftcards/scard6.png';
import card1 from './assests/nftcards/card1.png';
import card2 from './assests/nftcards/card2.png';
import card3 from './assests/nftcards/card3.png';
import card4 from './assests/nftcards/card4.png';
import card5 from './assests/nftcards/card5.png';
import card6 from './assests/nftcards/card6.png';
import one from './assests/newnft/01.png';
import two from './assests/newnft/02.png';
import three from './assests/newnft/03.png';
import four from './assests/newnft/04.png';
import five from './assests/newnft/05.png';
import six from './assests/newnft/06.png';





const images = [
  {
    id: 1,
    img: one,
  },
  {
    id: 2,
    img: two,
  },  
  {
    id: 3,
    img: three,
  },
  {
    id: 4,
    img: four,
  },
  {
    id: 5,
    img: five,
  },
  {
    id: 6,
    img: six,
  },
]


// const images = [
//     {
//       id: 1,
//       img: scard1,
//       card: card1,
//       postion: 'Captain',
//       rarity: 'Basic',
//       match: 'ICCWT20 2023',
//       location: 'India',
//       rate: '168',
//       owner: "Mohit Gupta"
//     },
//     {
//       id: 2,
//       img: scard2,
//       card: card2,
//       postion: 'Vice-Captain',
//       rarity: 'Basic',
//       match: 'ICCWT20 2023',
//       location: 'India',
//       rate: '168',
//       owner: "Rohit Sharma"
//     },
//     {
//       id: 3,
//       img: scard3,
//       card: card3,
//       postion: 'Batsman',
//       rarity: 'Basic',
//       match: 'ICCWT20 2023',
//       location: 'India',
//       rate: '168',
//       owner: "Raj Thakur"
//     },
//     {
//       id: 4,
//       img: scard4,
//       card: card4,
//       postion: 'Bowler',
//       rarity: 'Basic',
//       match: 'ICCWT20 2023',
//       location: 'India',
//       rate: '168',
//       owner: "Ritik Tripathi"
//     },
//     {
//       id: 5,
//       img: scard5,
//       card: card5,
//       postion: 'All Rounder',
//       rarity: 'Basic',
//       match: 'ICCWT20 2023',
//       location: 'India',
//       rate: '168',
//       owner: "Puneet Gupta"
//     },
//     {
//       id: 6,
//       img: scard6,
//       card: card6,
//       postion: 'Wicket Keeper',
//       rarity: 'Basic',
//       match: 'ICCWT20 2023',
//       location: 'India',
//       rate: '168',
//       owner: "Mohit",
//       owner: "Sanju Chaudhary"
//     }
//   ]


export default images;