import axios from "axios";


const axiosInstance = axios.create({
  baseURL: 'https://caro11.com/caro11_admin/api', 
  headers: {
    'Content-Type': 'application/json',
  }
});

export default axiosInstance;
