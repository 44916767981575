import { useMemo } from "react";
import { Link } from "react-router-dom";
const GroupComponent = ({ captain, propLeft, propWidth, propLineHeight }) => {
 
  const frameStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const questionStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const captainStyle = useMemo(() => {
    return {
      lineHeight: propLineHeight,
    };
  }, [propLineHeight]);

  return (
      <div  className="flex w-full flex-col h-full">
        <div className="relative rounded-[20px] [background:linear-gradient(180deg,_rgba(18,_71,_149,_0.8),_rgba(14,_19,_61,_0.8)_97.92%)] [backdrop-filter:blur(30.87px)]  bg-[url('/public/rectangle-7682.svg')] pb-[250px] sm:pb-[420px] md:pb-[457px] bg-no-repeat">
            <img
               className="w-full h-[258.9px] sm:w-full sm:h-[420.9px] md:w-full md:h-[498.9px] lg:w-[380.27px] lg:h-[450.9px] 2xl:w-full 2xl:h-[500px] absolute my-0 mx-[!important] bottom-0 left-1/2 transform -translate-x-1/2 object-contain z-[0]"
               alt=""
               src="/afabe4c797ce4c7c8860c0119a3345483x-1@2x.png"
             />
            <div className="absolute top-[0.3px] left-[0.6px] [background:linear-gradient(180deg,_rgba(22,_75,_153,_0)_80%,_#000002_97.92%)] w-full h-full z-[1]">
              {/* <div className="w-[213.5px] h-[332.6px] absolute top-[21px] left-[24px] rounded-[30.87px] z-[6] bg-[url('/public/rectangle-7682.svg')] bg-no-repeat" /> */}
              <div className="bottom-corner" />
              <div className="corner-border" />
              {/* <div className="w-[214px] h-[332.6px] absolute bottom-[21px] right-[24px] rounded-[30.87px] z-[6] bg-[url('/public/rectangle-7681.svg')] bg-no-repeat" /> */}
              
            </div>
          <div className="relative z-50">
            <div className="flex justify-between flex-row">
              <h2 className=" font-nunito-sans md:ml-[20px] lg:ml-[30px] 2xl:ml-[40px] h-[27.8px] text-[16px] sm:text-[20px] md:text-[30.87px] tracking-[0.03em] m-0 xs:pl-[50.22px] sm:px-[43.22px] pt-[64.81px] text-center leading-[100%] font-extrabold inline-block shrink-0 whitespace-nowrap z-[8]">$ 168</h2>
              <div className="pt-[52.47px] pr-[22.93px] " >
              <Link to={'/card-detail-page'} className="  xs:h-[44.17px]  sm:h-[55.17px] no-underline md:text-8xl-8 z-[999] leading-[100%] font-extrabold font-nunito-sans text-d2d45 text-left [background:linear-gradient(180deg,_#fefb57,_#e1ba14)] shadow-[0px_30.9px_77.17px_rgba(225,_186,_20,_0.36)]">
              <button className=" xs:h-[44.17px] sm:h-[55.17px] cursor-pointer   md:text-8xl-8 leading-[100%] font-extrabold font-nunito-sans text-d2d45 text-left [background:linear-gradient(180deg,_#fefb57,_#e1ba14)] shadow-[0px_30.9px_77.17px_rgba(225,_186,_20,_0.36)]" >
                
                Buy Now</button>
                
              </Link> 
              </div>          
            </div>
          </div>
        </div>
        <div className="relative">
        <div
             className="z-[2] h-[112.9px] w-[356.3px] absolute my-0 mx-[!important] top-[74.62px] left-[calc(50%_-_236px)] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_rgba(254,_225,_1,_0.3),_rgba(254,_225,_1,_0))] [transform:_rotate(-9.45deg)] [transform-origin:0_0]"
             style={frameStyle}
           />
           <div
           className="z-[-1] h-[calc(100%_-_28.4px)] w-[calc(100%_-_1px)] absolute my-0 mx-[!important] top-[25.2px] right-[2px] bottom-[3.2px] left-[-1px] rounded-t-none rounded-b-[15.43px] bg-darkslategray [backdrop-filter:blur(30.87px)] box-border border-[1.5px] border-solid border-cold-grey-white"
           style={questionStyle}
           />
          <div className="flex flex-row justify-between mt-7">
              <div className="flex flex-col px-[15.43px] py-[10.32px]">
                <h2 className=" text-d9d9d9 font-nunito-sans font-[800] leading-[20.04px] text-[16.04px] md:leading-[26.04px] md:text-[26.04px] lg:leading-[26.04px] lg:text-[26.04px] xl:leading-[37.04px] xl:text-[37.04px] m-0 mb-[12px]">LEFT handED</h2>
                <h3 className=" font-[600] font-nunito-sans leading-[16.04px] text-[12.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[20.04px] lg:text-[20.04px] xl:leading-[30.04px] xl:text-[30.04px] m-0 mb-[18px]">ICCWT20 2023</h3>
                <h2 className=" font-[700] font-nunito-sans leading-[20.04px] text-[16.04px] md:leading-[26.04px] md:text-[26.04px] lg:leading-[26.04px] lg:text-[26.04px] xl:leading-[37.04px] xl:text-[37.04px] m-0 mb-[18.52px]" >postion</h2>
              </div>
         <div className="flex flex-col items-end justify-start px-[25.39px] pt-[10.32px] text-cold-grey-white">
           <div className="flex flex-row items-start justify-start py-0 pb-[30px] sm:pb-[50px] md:pb-[37px]">
             <h2 className="font-[600] leading-[20.04px] font-nunito-sans text-[16.04px] md:leading-[26.04px] md:text-[26.04px] lg:leading-[26.04px] lg:text-[26.04px] xl:leading-[37.04px] xl:text-[37.04px] m-0 mt-[10.32px] ">
               India
             </h2>
           </div>
           <button className="cursor-pointer [border:none] pt-[9px] pb-2.5 pr-[15px] pl-2.5 bg-saddlebrown rounded-[6.17px] flex flex-row items-center justify-center z-[2] hover:bg-sienna">
             <div className="h-[46.3px] w-[123.5px] relative rounded-[6.17px] bg-saddlebrown hidden" />
             <div className="relative font-semibold font-nunito-sans text-gray-400 text-left z-[1] leading-[16.04px] text-[12.04px] md:leading-[20.04px] md:text-[20.04px] lg:leading-[20.04px] lg:text-[20.04px] xl:leading-[30.04px] xl:text-[30.04px]">
               Bronze
             </div>
           </button>
         </div>
         </div>
       </div>
    </div>
    
  );
};

export default GroupComponent;

  // return (
  //   <div className="h-[720px] w-[474px] shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-1 box-border text-left text-11xl-9 text-cold-grey-white font-nunito-sans">
  //     <div className="self-stretch h-[555px] rounded-t-[30.87px] rounded-b-none [background:linear-gradient(180deg,_rgba(18,_71,_149,_0.8),_rgba(14,_19,_61,_0.8)_97.92%)] [backdrop-filter:blur(30.87px)] box-border flex flex-col items-end justify-start pt-[21px] pb-[201px] pr-5 pl-6 relative max-w-full z-[2] border-[1.5px] border-solid border-cold-grey-white">
  //       <div className="w-[473px] h-[554.3px] relative rounded-t-[30.87px] rounded-b-none [background:linear-gradient(180deg,_rgba(18,_71,_149,_0.8),_rgba(14,_19,_61,_0.8)_97.92%)] [backdrop-filter:blur(30.87px)] box-border hidden max-w-full z-[1] border-[1.5px] border-solid border-cold-grey-white" />
  //       <div className="self-stretch h-[333px] flex flex-row items-start justify-start py-0 px-0.5 box-border">
  //         <div className="self-stretch w-[214px] flex flex-col items-start justify-start py-[43px] px-[19px] box-border relative">
  //           <img
  //             className="w-full h-full absolute my-0 mx-[!important] top-[0.3px] right-[0.8px] bottom-[0.1px] left-[-0.3px] rounded-[30.87px] max-w-full overflow-hidden max-h-full object-contain z-[7]"
  //             alt=""
  //             src="/rectangle-7682.svg"
  //           />
  //           <div className="h-[27.8px] relative tracking-[0.03em] leading-[100%] font-extrabold inline-block shrink-0 whitespace-nowrap z-[8] mq450:text-lgi mq450:leading-[19px] mq925:text-6xl mq925:leading-[25px]">
  //             ₹ 168
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-[470px] h-[377px] absolute my-0 mx-[!important] right-[2px] bottom-[1px]">
  //         <img
  //           className="absolute top-[373.5px] left-[-0.4px] w-[469.1px] h-[3.9px] z-[3]"
  //           alt=""
  //           src="/rectangle-7685.svg"
  //         />
  //         <div className="absolute top-[0.3px] left-[0.6px] [background:linear-gradient(180deg,_rgba(22,_75,_153,_0),_#000002_97.92%)] w-full h-full z-[5]" />
  //         <img
  //           className="absolute top-[23.4px] left-[232.1px] rounded-[30.87px] w-[213.5px] h-[332.6px] z-[6]"
  //           loading="eager"
  //           alt=""
  //           src="/rectangle-7681.svg"
  //         />
  //       </div>
  //       <button className="cursor-pointer [border:none] pt-[15px] pb-[13px] pr-3.5 pl-4 bg-[transparent] rounded-[6.17px] [background:linear-gradient(180deg,_#fefb57,_#e1ba14)] shadow-[0px_30.9px_77.17px_rgba(225,_186,_20,_0.36)] flex flex-row items-center justify-center relative whitespace-nowrap z-[3] mt-[-301px]">
  //         <div className="h-[55.2px] w-[153.7px] relative rounded-[6.17px] [background:linear-gradient(180deg,_#fefb57,_#e1ba14)] shadow-[0px_30.9px_77.17px_rgba(225,_186,_20,_0.36)] hidden z-[0]" />
  //         <div className="relative text-8xl-8 leading-[100%] font-extrabold font-nunito-sans text-d2d45 text-left z-[1]">
  //           Buy Now
  //         </div>
  //         <img
  //           className="h-[498.9px] w-[358.3px] absolute my-0 mx-[!important] bottom-[-446.1px] left-[-261.2px] object-cover z-[4]"
  //           alt=""
  //           src="/afabe4c797ce4c7c8860c0119a3345483x-1@2x.png"
  //         />
  //       </button>
  //     </div>
  //     <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[14px] pl-[18px] box-border  relative gap-[0px_62px] min-h-[186px] shrink-0 mt-[-25px] ml-[2px] text-18xl text-d9d9d9">
  //       <div
  //         className="h-[112.9px] w-[456.3px] absolute my-0 mx-[!important] top-[74.62px] left-[calc(50%_-_236px)] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_rgba(254,_225,_1,_0.3),_rgba(254,_225,_1,_0))] [transform:_rotate(-9.45deg)] [transform-origin:0_0]"
  //         style={frameStyle}
  //       />
  //       <div
  //         className="h-[calc(100%_-_28.4px)] w-[calc(100%_-_1px)] absolute my-0 mx-[!important] top-[25.2px] right-[2px] bottom-[3.2px] left-[-1px] rounded-t-none rounded-b-[15.43px] bg-darkslategray [backdrop-filter:blur(30.87px)] box-border z-[1] border-[1.5px] border-solid border-cold-grey-white"
  //         style={questionStyle}
  //       />
      //   <div className="flex-1 flex flex-col items-start justify-start gap-[5px_0px] min-w-[159px]">
      //     <div className="self-stretch flex flex-col items-start justify-start gap-[30px_0px]">
      //       <h2 className="mb-0 h-[33px] relative text-inherit leading-[100%] font-extrabold font-inherit inline-block shrink-0 z-[2] mq450:text-3xl mq450:leading-[22px] mq925:text-11xl mq925:leading-[30px]">
      //         Right-Handed
      //       </h2>
      //       <div className="mt-0 h-[27.8px] relative text-11xl-9 leading-[100%] font-semibold text-cold-grey-white inline-block shrink-0 z-[2] mq450:text-lgi mq450:leading-[19px] mq925:text-6xl mq925:leading-[25px]">
      //         ICCWT20 2023
      //       </div>
      //     </div>
      //     <div className="flex flex-row items-start justify-start py-0 px-[7px] text-cold-grey-white">
      //       <h2
      //         className="m-0 relative text-inherit leading-[33px] font-bold font-inherit whitespace-nowrap z-[2] mq450:text-3xl mq450:leading-[22px] mq925:text-11xl mq925:leading-[30px]"
      //         style={captainStyle}
      //       >
      //         {captain}
      //       </h2>
      //     </div>
      //   </div>
      //   <div className="flex flex-col items-end justify-start gap-[54px_0px] text-cold-grey-white">
      //     <div className="flex flex-row items-start justify-start py-0 px-2">
      //       <h2 className="mb-0 relative text-inherit leading-[33px] font-semibold font-inherit whitespace-nowrap z-[2] mq450:text-3xl mq450:leading-[22px] mq925:text-11xl mq925:leading-[30px]">
      //         India
      //       </h2>
      //     </div>
      //     <button className="cursor-pointer [border:none] pt-[9px] pb-2.5 pr-[15px] pl-2.5 bg-saddlebrown rounded-[6.17px] flex flex-row items-center justify-center z-[2] hover:bg-sienna">
      //       <div className="h-[46.3px] w-[123.5px] relative rounded-[6.17px] bg-saddlebrown hidden" />
      //       <div className="relative text-11xl-9 leading-[90%] font-semibold font-nunito-sans text-gray-400 text-left z-[1] mq450:text-lgi mq450:leading-[17px] mq925:text-6xl mq925:leading-[22px]">
      //         Bronze
      //       </div>
      //     </button>
      //   </div>
      // </div>
  //   </div>
  // );