import React from "react";
import apkFile from "../assests/app-release.apk"

const ReferEarn = () => {
  const handleDownload = () => {
    const apkUrl = apkFile
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'caro11-app.apk';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      <div className="bg-[#050b12] flex items-center justify-center w-[100%] text-white">
        <div className="max-w-[1200px] w-full mx-auto md:my-[100px] my-[50px]"  data-aos="fade-up" data-aos-duration="1000" >
          
          <div className=" md:px-[80px] md:py-[60px] py-[40px] px-[40px] grid grid-cols-1 md:grid-cols-2 gap-0 gridient-bg">
            <div className="image-container md:text-left text-center flex flex-col justify-center items-center md:items-left">
              <h2 className="text-white md:text-[50px] md:leading-[60px] text-[30px] mont italic m-0">
                Refer and Earn
              </h2>
              <p className="md:text-[24px] md:leading-[30px] text-[16px] mont font-normal mt-2">
                Earn Big Rewards By inviting your friends
              </p>
              <a href="https://drive.google.com/file/d/1nWcYHB3qlXJDvVr0oWkvk26c3-sNWL8j/view?usp=drive_link " target="_blank">
              <button className="download-button md:mt-[20px] ">
                Download App
              </button>
              </a>
            </div>
            <div className="image-container flex items-center justify-end md:mt-[20px] mt-[40px]">
              <div className="add-vector">
                <img className="z-10 w-[100%] md:w-[394px]" src="/img/earn-vector.png" />
              </div>
            </div>
          </div>

          <h2 className="text-white text-center md:pt-[80px] pt-[50px] md:text-[50px] md:leading-[60px] text-[30px] mont italic m-0">
          Track Us
              </h2>
          <div className=" md:py-[30px] py-[30px] grid grid-cols-1 md:grid-cols-2 md:gap-20 gap-6 md:w-[100%] w-[96%] mx-auto">
           
            <div className="image-container text-left flex justify-between items-center earn-bg">
            <h2 className="text-white md:text-[30px] md:leading-[36px] text-[24px] mont italic m-0">
              Caro Token
              </h2>
            <a href="https://bscscan.com/token/0x4aa1063a4537448cef8d93e7588fe3eb2af8e328/" target="_blank">  <button className="download-button ">
              Visit Contract
              </button></a>
            </div>

            <div className="image-container flex items-center justify-between  earn-bg">
            <h2 className="text-white md:text-[30px] md:leading-[36px] text-[24px] mont italic m-0">
            Caro NFT
              </h2>
              <a href="https://bscscan.com/token/0x010DfB5bc0e68c446a4fC04FE3aA0fC9dB01857A" target="_blank">   <button className="download-button">
              Visit Contract
              </button></a>
            </div>
            
          </div>



        </div>
      </div>
    </div>
  );
};

export default ReferEarn;
