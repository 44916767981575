import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import TC from "./pages/TC";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CardDetailPage from "./pages/CardDetailPage";
import "./App.css";
import AboutUs from "./pages/AboutUs";
import HowToPlay from "./pages/HowToPlay";
import RefundPage from "./pages/RefundPage";
import ReferNEarnPage from "./pages/ReferNEarnPage";
import Register from "./pages/Auth/Register";
import { Toaster } from "react-hot-toast";
import Otpvalidate from "./pages/Auth/Otpvalidate";
import Profile from "./pages/Auth/Profile";
import Download from "./pages/Auth/Download";
import "react-country-state-city/dist/react-country-state-city.css";
import Home from "./pages/Home";
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  
  useEffect(() => {
    AOS.init({
      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      },
      duration: 1000 // Optional: Animation duration in milliseconds
    });
  }, []);

  

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/tc":
        title = "";
        metaDescription = "";
        break;
      case "/privacy-policy":
        title = "";
        metaDescription = "";
        break;
      case "/card-detail-page":
        title = "";
        metaDescription = "";
        break;
      case "/about-us":
        title = "";
        metaDescription = "";
        break;
      case "/refund-policy":
        title = "";
        metaDescription = "";
        break;
      case "/refer-&-earn":
        title = "";
        metaDescription = "";
        break;
      case "/how-to-play":
        title = "";
        metaDescription = "";
        break;
        case "/register":
        title = "";
        metaDescription = "";
        break;
        case "/otp":
          title = "";
          metaDescription = "";
          break;
          case "/profile":
            title = "";
            metaDescription = "";
            break;
            case "/download":
              title = "";
              metaDescription = "";
              break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <>
    <Routes>
      {/* <Route path="/" element={<LandingPage />} /> */}
      <Route path="/tc" element={<TC />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/card-detail-page/:id" element={<CardDetailPage />} />
      <Route path="/refund-policy" element={<RefundPage />} />
      <Route path="/refer-&-earn" element={<ReferNEarnPage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/how-to-play" element={<HowToPlay />} />
      <Route path="/register/:inviteCode" element={<Register />} />
      <Route path="/register" element={<Register />} />
      <Route path="/otp" element={<Otpvalidate />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/download" element={<Download />} />
      <Route path="/" element={<Home />} />




    
    </Routes>
    <Toaster position="bottom-center"/>
    </>
  );
}
export default App;
