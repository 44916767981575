import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import MatchCard from './MatchCard';
import "../../src/App.css"

function GroupeLeague() {
  return (
    <div className="text-white flex flex-col w-full md:gap-[72px] gap-[30px] px-[20px] xl:px-[120px] pt-[64px] bg-[#050b12] bg-[url('/public/bg-2@2x.png')] bg-no-repeat">
      <div  data-aos="fade-up" data-aos-duration="1000" className="md:pt-[40px] text-center mont text-white italic font-[700] leading-[30.04px] font-Montserrat text-[30.04px] md:leading-[66px] md:text-[44px]">
        Global Leagues
        </div>
        <div className=""  data-aos="fade-up" data-aos-duration="1500">
        <Swiper cssMode={true}
              navigation={true}
              slidesPerView={2}
              direction={'horizontal'}
              spaceBetween={90}
              mousewheel={true}
              keyboard={true}
              centeredSlides={true}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                // 500: {
                //   slidesPerView: 1.25,
                //   spaceBetween: 30,
                // },
                805: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
              }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className=" px-0 md:px-32 md:mb-[100px] mb-[70px] mySwiper gL"
            >
            <SwiperSlide className="swiper-slide-group"><MatchCard /></SwiperSlide>
            <SwiperSlide className="swiper-slide-group"><MatchCard /></SwiperSlide>
            <SwiperSlide className="swiper-slide-group"><MatchCard /></SwiperSlide> 
            <SwiperSlide className="swiper-slide-group"><MatchCard /></SwiperSlide> 
            <SwiperSlide className="swiper-slide-group"><MatchCard /></SwiperSlide> 

            </Swiper>
        </div>
  </div>
  )
}

export default GroupeLeague