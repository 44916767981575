import Footer from "../components/Footer";
import Nav from "../components/Nav";

const PrivacyPolicy = () => {
 
  return (
    <>
    <div className="w-full relative bg-gray-200 overflow-hidden flex flex-col items-center justify-start pt-10 px-0 box-border gap-[173px_0px] tracking-[normal] mq1125:gap-[173px_0px] mq800:gap-[173px_0px] pb-4">
      <img
        className="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/bg@2x.png"
      />
      <section className="w-[1720px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[11px_0px] max-w-full shrink-0 text-justify text-xl text-cold-grey-white font-montserrat">
        <Nav />
        <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[69px] box-border max-w-full text-left text-45xl">
          <h1 className="m-0 relative text-inherit uppercase font-normal font-inherit z-[1] mq450:text-19xl mq800:text-32xl">
            PRIVACY POLICY
          </h1>
        </div>
        <div className=" w-full flex flex-col gap-2 py-0 px-6 md:px-32">          
        <div className=" underline relative inline-block max-w-full z-[1] text-[1.5rem]">
        LAST UPDATED: 28 FEB 2024
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        At Caronft.com, we take our commitment to safeguarding your privacy seriously. We have
        prepared this Privacy Policy to outline our practices regarding the Personal Data we collect from
        users of our online services and Website, located at <a style={{color:"#cca016"}} href="https://www.caro11.com">Caronft.com</a> (the "Site") and
        ensure transparency in our dealings with your personal information.
        </div> 
        <div className="self-stretch relative z-[1] mq450:text-base">
        If you have any queries about how we handle your personal information, please get in touch with
        us at         <a style={{color:"#cca016"}} href="mailto:support@caronft.com">support@caronft.com</a>. For all matters related to user privacy, it's essential to note that legal
        concerns should only be settled in the courts/jurisdiction of UAE, with Meta Oryx Online Gaming
        Services as the legal entity responsible for user privacy.
        </div>

        <div className="self-stretch relative z-[1] mq450:text-base">
        We collect "Personal Data" that identifies or allows someone to contact you, including your name,
        telephone number, email address, and any other non-public information associated with or linked
        to the preceding data. Further, we also collect "Anonymous Data," which includes aggregated and
        de-identified data that does not permit the identification of individual persons on its own.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We collect Personal Data and Anonymous Data in the following ways:
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        <strong>Information You Provide Us:</strong> When you create an account to access our network ("Account") or
        seek support for our Services via email, we may collect Personal Data from you, such as your first
        and last name, email and mailing addresses, Blockchain address, and Caronft.com password. If
        you use our Services on your mobile device, we may collect your phone number and unique
        device ID number and associate these choices with your ID, browser, or mobile device.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        <strong>Information Collected via Technology:</strong> We use automatic data collection technologies to collect
        specific information about browsing actions, patterns, and equipment as you interact with our
        Service and navigate through it. We gather information such as your browser type, operating
        system, Internet Protocol ("IP") address, domain name, Blockchain address, wallet type, and a
        date/time stamp for your visit. We automatically gather certain information and store it in log files,
        such as IP addresses, browser type, Internet service provider ("ISP"), referring/exit pages,
        operating system, date/time stamp, and clickstream data.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We use this information to analyse trends, administer the Service, track user movements, gather
        demographic information about our user base, and better tailor our Services to our users' needs.
        For example, we use some of this information to serve advertisements and other information
        appropriate to your interests.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We take your privacy seriously and are committed to ensuring that your personal information is
        safe with us. We are committed to safeguarding your privacy at Caronft.com ("CARO NFT," we", or
        "us"). This Privacy Policy has been prepared to outline our practices concerning Personal Data (as
        defined below) that we collect from users of our online services (collectively, the "Service" or
        "Services") and our Website, located at <a style={{color:"#cca016"}} href="https://www.caro11.com">Caronft.com</a> (the "Site").
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        If you have any questions about how we handle your personal information, please contact us at 
        your personal information, please contact us at
                <a style={{color:"#cca016"}} href="mailto:support@caronft.com">support@caronft.com</a>.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        For all matters related to user privacy, legal concerns should only be settled in the courts/
        jurisdiction of the UAE. The legal entity responsible for user privacy is Meta Oryx Online Gaming
        Services LLC.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        "<strong>Personal Data: </strong> Personal data refers to data that identifies or allows someone to contact you,
        including, for instance, your name, telephone number, and email address, as well as any other
        non-public information associated with or linked to the preceding data. "Anonymous Data" means
        data, including aggregated and de-identified data, that is not associated with or linked to your
        Personal Data; Anonymous Data does not permit the identification of individual persons on its
        own. We collect Personal Data and Anonymous Data as specified below.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        <strong>Information You Provide Us:</strong>
        When you create an account to access our network ("Account"), we may collect Personal Data
        from you, such as your first and last name, email and mailing addresses, Blockchain address, and
        Caronft.com password.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        If you use our Services on your mobile device, we may collect your phone number and unique
        device ID number. Our Service allows you to customise preferences such as your content display,
        location, safe search settings, notification settings, and favourite widgets. We may associate
        these choices with your ID, browser or mobile device, and you can modify these preferences
        anytime.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We may receive identification from a service provider that uniquely identifies your mobile device
        when you connect to our Services, and we may use this information to provide extended services
        and functionality. Certain Services, such as two-factor authentication, may require us to collect
        your phone number, and we may associate that phone number with your mobile device
        identification information. If you provide feedback or contact us via email, we will collect your
        name, email address, and any other content included in the email to reply. We also collect various
        types of Personal Data that you voluntarily provide to us, such as your operating system and
        version, product registration number, Blockchain address, and other requested information if you
        seek support for our Services via email. We may also collect Personal Data at different points in
        our Service that indicate that Personal Data is being collected.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        <strong>Information Collected via Technology:</strong>
        As you interact with our Service and navigate through it, we may use automatic data collection
        technologies to collect specific information about your browsing actions, patterns, and
        equipment, including:
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        <strong>Information Collected by Our Servers:</strong> To make our Service more helpful to you, our servers
        (which may be hosted by a third-party service provider) may collect information from you,
        including your browser type, operating system, Internet Protocol ("IP") address (a number that is
        automatically assigned to your computer when you use the Internet, which may vary from session
        to session), domain name, Blockchain address, wallet type, and a date/time stamp for your visit.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        <strong>Log Files:</strong> We automatically gather certain information and store it in log files, which is typical of
        most websites. This information includes IP addresses, browser type, Internet service provider
        ("ISP"), referring/exit pages, operating system, date/time stamp, and clickstream data. We use
        this information to analyse trends, administer the Service, track users' movements around the
        Service, gather demographic information about our user base, and better tailor our Services to
        our users' needs. For example, some of the information may be collected so that when you visit
        the Service, it will recognise you, and the information could then be used to serve advertisements
        and other information appropriate to your interests.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        Our company uses cookies to collect information, as is common among online services. These
        cookies are small information sent by a website to your computer's hard drive while viewing the
        website. To provide you with a more personalised and interactive experience on our service, we
        may use both session cookies (which expire when you close your web browser) and persistent
        cookies (which remain on your computer until you delete them or until a specified duration -
        whichever is earlier). We collect this information to make our service more useful and tailor the
        experience to meet your special interests and needs.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        In addition to cookies, we may also use pixel tags to track the online movements of web users.
        Pixel tags are tiny graphic images with a unique identifier, similar to cookies, used to track your
        activity on the website. Unlike cookies, pixel tags are embedded invisibly in web pages. Pixel tags
        also allow us to send email messages in a format that users can read, and they tell us whether
        emails have been opened to ensure that we are sending only messages of interest to our users.
        We do not link the information gathered by pixel tags to our users' data.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We do not respond to "do not track" signals or other mechanisms that might enable users to opt
        out of tracking on our site analytics services. We engage third-party analytics services, including
        but not limited to Google Analytics, to help us analyse how users use our service. We use the
        information generated by these services to improve our service. The analytics services may also
        transfer information to third parties where required by law or where such third parties process
        analytics information on their behalf. Each analytics service's ability to use and share analytics
        information is restricted by its terms of use and privacy policy. By using our service, you consent
        to analytics services processing data about you in the manner and for the purposes set out
        above. If you require a complete list of analytics services, please get in touch with us.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We may also receive personal and anonymous data about you from third-party companies that
        provide our services through a co-branded or private-labelled website or companies that offer
        their products and services on our service. These companies may supply us with personal data,
        which we may add to the information we have already collected from you via our service to
        improve it. We do not collect personal data automatically, but we may tie the information we
        collect automatically to personal data about you from other sources or that you provide.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We use the personal data you submit to us to respond to your requests or to aid us in serving you
        better. We use your data to facilitate the creation and security of your account on our network,
        identify you as a user in our system, provide improved administration of our service, provide the
        services you request, including facilitating your cryptocurrency transactions through wallets and
        other payment mechanisms, improve the quality of experience when you interact with our service,
        send you a welcome email and a one-time password to verify your email address, protect you and
        other users from any conduct that violates our terms and conditions or to prevent abuse or
        harassment of any user, display your username next to the digital assets you have bought/
        acquired on the website, send you administrative email notifications, such as security or support
        and maintenance advisories, send you email notifications related to actions on the service,
        including notifications of offers on your digital assets, respond to your inquiries related to
        employment opportunities or other requests, occasionally make telephone calls to you, from time
        to time, as a part of secondary fraud protection or to solicit your feedback, and send newsletters,
        surveys, offers, and other promotional materials related to our services and for other marketing
        purposes of our company. We may also use your data to contact you about goods and services
        offered by third parties that may interest you.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We may share your Personal Data with third-party service providers to provide you with the
        Services we offer through our Service, to carry out quality assurance testing, to facilitate the
        creation of accounts, to provide technical support, and to provide other services to Caronft.com
        and Meta Oryx Online Gaming Services LLC.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        If necessary, we may share some or all of your Personal Data with our parent company,
        subsidiaries, joint ventures, or other companies under common control ("Affiliates"), provided they
        agree to comply with this Privacy Policy.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        In the event of a sale, transfer, divestiture, or disclosure of all or a portion of our business or
        assets, or insolvency, bankruptcy, or receivership, Personal Data may be transferred as a
        business asset.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We may disclose Personal Data when we believe in good faith that such disclosure is necessary
        to comply with applicable laws, respond to subpoenas or warrants served on Caro NFT, protect or
        defend the rights or property of Caro NFT or Service users, or investigate or prevent any violation or
        potential violation of the law, this Privacy Policy, or our Terms of Use.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We may use your Data for the purpose for which you provide it, for any other purpose disclosed
        by us when you provide it, or with your consent. We do not sell your data to third-party websites,
        but our Service may include links to such websites. We have no control over their content or
        privacy policies, and we encourage you to review them when you visit them.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We have established relationships with third-party payment services, such as Fracto, to conduct
        transactions on our Website, and their exclusive privacy policies govern any interaction with the
        third-party payment services provider.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        You have several options for managing the use of information on our Services, including the ability
        to unsubscribe from newsletters and promotional emails and to adjust your browser settings to
        disable or modify Cookies. However, if you choose to disable Cookies, some portions of the
        Service may not be available.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        We may retain your Data for as long as you continue to use the Service, have an account with us,
        or as long as necessary to fulfil the purposes outlined in this Privacy Policy. We will delete your
        data upon request, but we may retain Personal Data for additional periods as permitted or
        required under applicable laws or for legitimate and lawful business purposes.
        </div>
        <div className="self-stretch relative z-[1] mq450:text-base">
        <strong>Disclaimer:</strong> We take the security of your information seriously and use physical, administrative,
        and technological safeguards to protect the integrity and security of all information collected
        through our Service.
        </div>
        {/* <div className=" relative inline-block max-w-full z-[1] mq450:text-base">
        <a style={{color:"#cca016"}} href="mailto:support@<b>Caro11</b>.com">support@caro11.com</a>
        </div>           */}
        </div>
      </section>
    </div>
    <div className =" w-full relative bg-gray-200 overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border tracking-[normal] text-right text-17xl text-cold-grey-white font-montserrat" 
      style={{zoom: "0.8"}}>
        <Footer />
    </div>
    </>
  );
};

export default PrivacyPolicy;