import React from 'react'
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const RefundPage = () => {
  return (
    <>
    <div className="w-full relative bg-gray-200 overflow-hidden flex flex-col items-center justify-start pt-10 px-0 box-border gap-[173px_0px] tracking-[normal] mq1125:gap-[173px_0px] mq800:gap-[173px_0px] pb-5">
      <img
        className="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/bg@2x.png"
      />
      <section className="w-[1720px] z-20 flex flex-col items-center justify-start py-0 px-5 box-border gap-[11px_0px] max-w-full shrink-0 text-justify text-xl text-cold-grey-white font-montserrat">
        <Nav />
        <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[69px] box-border max-w-full text-left text-45xl">
          <h1 className="m-0 relative text-inherit uppercase font-normal font-inherit z-[1] mq450:text-19xl mq800:text-32xl">{`Refund Policy`}</h1>
        </div>
        <div className=" w-full py-0 px-0 md:px-32">
        <div className=" flex flex-col items-start justify-start gap-[11px_0px]">
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Refund Policy</h3>
        <div>Refunds: <br />
        Under no circumstances will any form of refund be entertained, especially for completed
        transactions. The platform is not responsible for wrongly conducted transactions, transactions
        carried out with wrong addresses, transactions carried out due to any malicious link/website/
        app/media/claim in any form impersonating the platform, and transactions attempted without
        complying with the set standards.</div>
        <div>Funds in Wallet: <br />
        The funds added to your wallet will become available for withdrawal nine days after the
        completion of the drop.</div>
        <div>Digital Collectible Transactions: <br />
        Under no circumstances will a completed transaction for the purchase of a Digital Collectible be
        reversed or refunded.</div>
        <div>Lost Auctions: <br />
        The bid amount will be returned to your Wallet if you lose an auction. However, the ninth-day
        withdrawal interval still applies.</div>
        <div>Active Auctions: <br />
        If you have placed a bid and the auction is still active, your Wallet balance will not be eligible for
        withdrawal.</div>
        <h3 className="my-3 text-primary fs-18 font-weight-bold">Refund Rules</h3>
        <div>The platform reserves the right to modify the rules of refunds, and it will be updated in the terms
        and conditions. Please check the Terms page for the latest version of the rules and policies.</div>
        <div>Different Selling Formats for Launchpad: <br />
        Digital collectibles are available for direct purchase, pre-booking, auction, or limited order. If
        you purchase a Digital Collectible at an advertised list price, your spending balance will be
        permanently reduced.</div>
        <div>Auction Method: <br />
        The platform follows the classic English auction method. The auction will be active for a specific
        period, during which the Digital Collectible will be listed, and the minimum bid value will be set.
        The highest bidder at the end of the auction will be eligible to own the Digital Collectible,
        subject to meeting the required conditions.</div>
        </div>
        </div>
      </section>
    </div>
    <div className =" w-full relative bg-gray-200 overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border tracking-[normal] text-right text-17xl text-cold-grey-white font-montserrat" 
    style={{zoom: "0.8"}}>
       <Footer />
   </div>
   </>
  );
};

export default RefundPage