import React, {  useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from "react-otp-input";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import useCountdown from './useCountdown';
import Loader from "../../config/Loader";

const Otpvalidate = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  console.log(otp)
  const [registerResponse, setRegisterResponse] = useState(null);
  const userEmail = localStorage.getItem('userEmail');
  useEffect(() => {
    const savedResponse = localStorage.getItem('registerResponse');
    if (savedResponse) {
      setRegisterResponse(JSON.parse(savedResponse));
    }
  }, []);
  const { minutes, seconds } = useCountdown(90);
  const handleSubmit = async () => {
    let temp = registerResponse.tempuser
    setLoading(true);
    try {
      const payload = {
        tempuser: temp,
        otp: otp
      };
      const response = await axios.post('https://caro11.com/caro11_admin/api/registerusers', payload);
      console.log('API Response:', response.data);
    
      if (Array.isArray(response.data) && response.data.length > 0) {
        const firstItem = response.data[0];
        if (firstItem.success) {
          toast.success(firstItem.message, {
            position: 'top-right',
            duration: 5000,
          });
          localStorage.setItem('registerResponse', JSON.stringify(firstItem));
          navigate('/profile');
        } else {
          response.data.forEach(item => {
            toast.error(item.message, {
              position: 'top-right',
              duration: 5000,
            });
          });
        }
      } else {
        toast.error('An error occurred while validating the OTP.', {
          position: 'top-right',
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('Error validating OTP:', error);
    
      if (error.response && Array.isArray(error.response.data)) {
        error.response.data.forEach(item => {
          toast.error(item.message, {
            position: 'top-right',
            duration: 5000,
          });
        });
      } else {
        toast.error('An unexpected error occurred.', {
          position: 'top-right',
          duration: 5000,
        });
      }
    }finally {
      setLoading(false);
    }
  };

  return (
    <section id="banner" className="bg-gray-900 banner">
      {loading && (<Loader/>)}
      <div className="container mx-auto sm:py-8 py-2 px-4">
        
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 text-center md:text-left">
            <div className="flex items-center justify-start md:justify-start mt-4 md:mt-0 space-x-4 pos-ab">
              <img className="w-16" src="/img/carlo-logo.svg" alt="Logo" />
              <h2 className="sub-heading font-yellow">CaroNFT</h2>
            </div>
            <div className="mt-4 w-full md:w-3/4">
              <img
                className="mx-auto bonus-img"
                src="/img/Bonus-img.png"
                alt="bonus"
                height="294"
                width="381"
              />
              <h6 className="sub-heading-small text-white text-center mt-4 mb-0 ">Just a Step Away to get exciting rewards worth up to</h6>
              <h3 className="sub-heading-bold font-yellow text-center sm:mt-3 mt-3 mb-0">$2000 NFTs</h3>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <Link className="hover-none" to="/register">
              <h1 className="main-heading text-white flex items-center">
                <img className="mr-3" src="/img/arrow-right.svg" alt="" /> Verification Email
              </h1>
            </Link>
            <h4 className="text-white mt-5 opt-heading mb-0">
              Please Enter 6 Digit <span className="font-yellow">OTP</span>
            </h4>
            <p className="text-white mt-2">Sent to: {userEmail}</p>
            <div className="otp-send flex flex-wrap gap-4 mt-4">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="otp-input w-16 h-16 bg-gray-800 text-white text-center rounded-md"
                  />
                )}
              />
              <p className="text-white text-start mt-2 w-full">
              Expiring in: {minutes}:{seconds < 10 ? `0${seconds}` : seconds} Min
              </p>
            </div>
            <div className="mt-5 text-left md:mt-[60%] mt-[20px] ">
             
                <button className="btn-style mt-5 w-full py-2 rounded-lg" onClick={handleSubmit}>
                  Submit
                </button>
              
              <div className="text-white mt-4 box-w">
                <p className="text-center">
                  By Registering, I Agree to{" "}
                  <span className="font-yellow"><b>CaroNFT</b></span> T&Cs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Otpvalidate;
